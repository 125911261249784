import { Injectable } from '@angular/core';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';
import { FreckleLinkGeneratorV2Service } from 'src/app/services/link-generators-v2/freckle-link-generator-v2.service';
import { FreckleLinkGeneratorService } from 'src/app/services/link-generators/freckle-link-generator.service';
import { ProductTypeGroups } from 'src/app/services/product-info/product-info.service';
import { RecommendationType } from 'src/app/services/recommendation/RecommendationType';
import { SubjectTypes } from 'src/app/services/subject/subject-types';
import { BaseRecommendationSkill, ActionLink } from 'src/app/shared/models/recommendation.model';
import { SkillMetadata } from 'src/app/shared/models/skill-metadata.model';
import { Student } from 'src/app/shared/models/student.model';
import { Link } from 'src/app/util/link/link';
import { OverlapSkillRecommendationService } from '../../overlap-skill/overlap-skill-recommendation.service';

@Injectable({
  providedIn: 'root'
})
export class FreckleActionRecommendationService {
  private readonly FRECKLE_IMAGE_PATH = `../../../../../../assets/logos/freckle_logo.svg`;

  constructor(
    private freckleLinkGenService: FreckleLinkGeneratorService,
    private overlapSkillRecommendationService: OverlapSkillRecommendationService,
    private featureToggleService: FeatureToggleService,
    private freckleLinkGeneratorV2Service: FreckleLinkGeneratorV2Service
  ) { }

  public async getFreckleActionForRecommendation(skill: BaseRecommendationSkill, freckleMetadata: SkillMetadata | null, frecklePrereqMetadata: SkillMetadata | null, students: Student[], hasPracticeProductAssigned: boolean): Promise<ActionLink | null> {

    // Use old logic if feature toggle is off
    if (!await this.featureToggleService.isTrueAsync('use-rgp-standard-sets-for-freckle-recommendations')) {
      return this.getFreckleActionForRecommendationOld(skill, freckleMetadata, students, hasPracticeProductAssigned);
    }

    let actionLink = null;

    const isFreckleLaliloOverlapSkill = await this.featureToggleService.isTrueAsync('enable-lalilo-take-action-recommendations') &&
      !!freckleMetadata &&
      this.overlapSkillRecommendationService.isFreckleLaliloOverlapSkill(freckleMetadata.contentActivityId);

    // Only recommend freckle practice for
    // 1. targeted practice if the skill is not a Lalilo overlap skill
    // 2. star recommendation if the skill is not an overlap skill
    if (hasPracticeProductAssigned && freckleMetadata &&
      ((skill.practiceProductType &&
        ProductTypeGroups.TargetedPracticeIds.includes(skill.practiceProductType) &&
        !isFreckleLaliloOverlapSkill) ||
        skill.recommendationType === RecommendationType.Star && !isFreckleLaliloOverlapSkill
      )) {

      const freckleUrl = this.getFreckleActionLinkUrl(skill, freckleMetadata, frecklePrereqMetadata, students);

      if (freckleUrl) {
        if (skill.recommendationType === RecommendationType.NeedsHelp) {
          actionLink = {
            link: new Link(freckleUrl),
            name: 'Prerequisite Skill Practice in Freckle',
            description: 'Assign practice on prerequisite skills',
            imageUrl: this.FRECKLE_IMAGE_PATH
          };
        }

        else if (skill.recommendationType === RecommendationType.Appropriate) {
          actionLink = {
            link: new Link(freckleUrl),
            name: 'Skill Practice in Freckle',
            description: 'Assign more practice on this skill',
            imageUrl: this.FRECKLE_IMAGE_PATH
          }
        }

        else if (skill.recommendationType === RecommendationType.NeedsChallenge) {
          actionLink = {
            link: new Link(freckleUrl),
            name: 'Skill Practice in Freckle',
            description: 'Assign practice on this skill',
            imageUrl: this.FRECKLE_IMAGE_PATH
          };
        }

        else if (skill.recommendationType === RecommendationType.Star) {
          actionLink = {
            link: new Link(freckleUrl),
            name: 'Skill Practice in Freckle',
            description: 'Assign practice on this skill',
            imageUrl: this.FRECKLE_IMAGE_PATH
          };
        }
      }
    }
    return actionLink;
  }

  private getFreckleActionLinkUrl(skill: BaseRecommendationSkill, freckleMetadata: SkillMetadata, frecklePrereqMetadata: SkillMetadata | null, students: Student[]): string {
    let freckleUrl: string = '';
    if (this.featureToggleService.isTrue("use-new-launch")) {
      // Freckle Math
      if (skill.subject === SubjectTypes.MATH) {
        if (skill.recommendationType === RecommendationType.NeedsHelp) {
          freckleUrl = this.freckleLinkGeneratorV2Service.getAssignPrereqSkillDeepLink(freckleMetadata, students);
        }

        else if (skill.recommendationType === RecommendationType.Appropriate ||
          skill.recommendationType === RecommendationType.NeedsChallenge ||
          skill.recommendationType === RecommendationType.Star) {
          freckleUrl = this.freckleLinkGeneratorV2Service.getAssignSkillDeepLink(freckleMetadata, students);
        }
      }

      // Freckle Reading
      else if (skill.subject === SubjectTypes.READING) {
        if (skill.recommendationType === RecommendationType.NeedsHelp && frecklePrereqMetadata) {
          freckleUrl = this.freckleLinkGeneratorV2Service.getAssignElaSkillsPracticeDeepLink(frecklePrereqMetadata, students);
        }

        else if (skill.recommendationType === RecommendationType.Appropriate ||
          skill.recommendationType === RecommendationType.NeedsChallenge ||
          skill.recommendationType === RecommendationType.Star) {
          freckleUrl = this.freckleLinkGeneratorV2Service.getAssignElaSkillsPracticeDeepLink(freckleMetadata, students);
        }
      }
    }
    else {
      if (skill.subject === SubjectTypes.MATH) {
        if (skill.recommendationType === RecommendationType.NeedsHelp) {
          freckleUrl = this.freckleLinkGenService.getAssignPrereqSkillLink(freckleMetadata, students);
        }

        else if (skill.recommendationType === RecommendationType.Appropriate ||
          skill.recommendationType === RecommendationType.NeedsChallenge ||
          skill.recommendationType === RecommendationType.Star) {
          freckleUrl = this.freckleLinkGenService.getAssignSkillLink(freckleMetadata, students);
        }
      }

      // Freckle Reading
      else if (skill.subject === SubjectTypes.READING) {
        if (skill.recommendationType === RecommendationType.NeedsHelp && frecklePrereqMetadata) {
          freckleUrl = this.freckleLinkGenService.getAssignElaSkillsPracticeLink(frecklePrereqMetadata, students);
        }

        else if (skill.recommendationType === RecommendationType.Appropriate ||
          skill.recommendationType === RecommendationType.NeedsChallenge ||
          skill.recommendationType === RecommendationType.Star) {
          freckleUrl = this.freckleLinkGenService.getAssignElaSkillsPracticeLink(freckleMetadata, students);
        }
      }
    }

    return freckleUrl;
  }

  private getFreckleActionForRecommendationOld(skill: BaseRecommendationSkill, freckleMetadata: SkillMetadata | null, students: Student[], hasPracticeProductAssigned: boolean): ActionLink | null {

    if (hasPracticeProductAssigned && freckleMetadata) {
      // Return null action link if type is Adaptive since we only recommend freckle practice for targeted
      if (skill.practiceProductType && ProductTypeGroups.AdaptivePracticeIds.includes(skill.practiceProductType)) {
        return null;
      }

      if (freckleMetadata && skill.subject === SubjectTypes.MATH) {
        return this.getFreckleMathActionForRecommendation(skill, freckleMetadata, students);
      }

      if (freckleMetadata && skill.subject === SubjectTypes.READING) {
        return this.getFreckleReadingActionForRecommendation(skill, freckleMetadata, students);
      }
    }
    return null;
  }

  private getFreckleMathActionForRecommendation(skill: BaseRecommendationSkill, freckleMetadata: SkillMetadata, students: Student[]): ActionLink | null {
    let actionLink = null;
    if (this.featureToggleService.isTrue("use-new-launch")) {
      if (skill.recommendationType === RecommendationType.NeedsHelp) {
        const freckleDeepLink = this.freckleLinkGeneratorV2Service.getAssignPrereqSkillDeepLink(freckleMetadata, students);
        actionLink = {
          link: new Link(freckleDeepLink),
          name: 'Assign practice on prerequisite skills',
          description: 'Assign practice on prerequisite skills',
          imageUrl: this.FRECKLE_IMAGE_PATH
        };
      }

      else if (skill.recommendationType === RecommendationType.Appropriate) {
        const freckleDeepLink = this.freckleLinkGeneratorV2Service.getAssignSkillDeepLink(freckleMetadata, students);
        actionLink = {
          link: new Link(freckleDeepLink),
          name: 'Assign more practice on this skill',
          description: 'Assign more practice on this skill',
          imageUrl: this.FRECKLE_IMAGE_PATH
        }
      }

      else if (skill.recommendationType === RecommendationType.NeedsChallenge) {
        const freckleDeepLink = this.freckleLinkGeneratorV2Service.getAssignSkillDeepLink(freckleMetadata, students);
        actionLink = {
          link: new Link(freckleDeepLink),
          name: 'Assign practice on this skill',
          description: 'Assign practice on this skill',
          imageUrl: this.FRECKLE_IMAGE_PATH
        };
      }

      else if (skill.recommendationType === RecommendationType.Star) {
        const freckleDeepLink = this.freckleLinkGeneratorV2Service.getAssignSkillDeepLink(freckleMetadata, students);
        actionLink = {
          link: new Link(freckleDeepLink),
          name: 'Skill Practice in Freckle',
          description: 'Assign practice on this skill',
          imageUrl: this.FRECKLE_IMAGE_PATH
        };
      }
    }
    else {
      if (skill.recommendationType === RecommendationType.NeedsHelp) {
        const freckleUrl = this.freckleLinkGenService.getAssignPrereqSkillLink(freckleMetadata, students);
        actionLink = {
          link: new Link(freckleUrl),
          name: 'Assign practice on prerequisite skills',
          description: 'Assign practice on prerequisite skills',
          imageUrl: this.FRECKLE_IMAGE_PATH
        };
      }

      else if (skill.recommendationType === RecommendationType.Appropriate) {
        const freckleUrl = this.freckleLinkGenService.getAssignSkillLink(freckleMetadata, students);
        actionLink = {
          link: new Link(freckleUrl),
          name: 'Skill Practice in Freckle',
          description: 'Assign more practice on this skill',
          imageUrl: this.FRECKLE_IMAGE_PATH
        }
      }

      else if (skill.recommendationType === RecommendationType.NeedsChallenge) {
        const freckleUrl = this.freckleLinkGenService.getAssignSkillLink(freckleMetadata, students);
        actionLink = {
          link: new Link(freckleUrl),
          name: 'Skill Practice in Freckle',
          description: 'Assign practice on this skill',
          imageUrl: this.FRECKLE_IMAGE_PATH
        };
      }

      else if (skill.recommendationType === RecommendationType.Star) {
        const freckleUrl = this.freckleLinkGenService.getAssignSkillLink(freckleMetadata, students);
        actionLink = {
          link: new Link(freckleUrl),
          name: 'Skill Practice in Freckle',
          description: 'Assign practice on this skill',
          imageUrl: this.FRECKLE_IMAGE_PATH
        };
      }
    }

    return actionLink;
  }

  private getFreckleReadingActionForRecommendation(skill: BaseRecommendationSkill, freckleMetadata: SkillMetadata, students: Student[]): ActionLink | null {
    let actionLink = null;
    if (this.featureToggleService.isTrue("use-new-launch")) {
      if (skill.recommendationType === RecommendationType.Star) {
        const freckleUrl = this.freckleLinkGeneratorV2Service.getAssignElaSkillsPracticeDeepLink(freckleMetadata, students);
        actionLink = {
          link: new Link(freckleUrl),
          name: 'Skill Practice in Freckle',
          description: 'Assign practice on this skill',
          imageUrl: this.FRECKLE_IMAGE_PATH
        }
      }
    }
    else {
      if (skill.recommendationType === RecommendationType.Star) {
        const freckleUrl = this.freckleLinkGenService.getAssignElaSkillsPracticeLink(freckleMetadata, students);
        actionLink = {
          link: new Link(freckleUrl),
          name: 'Skill Practice in Freckle',
          description: 'Assign practice on this skill',
          imageUrl: this.FRECKLE_IMAGE_PATH
        }
      }
    }
    return actionLink;
  }
}
