import { Component, ViewChild, Input, OnInit, OnChanges } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ClassProducts } from 'src/app/shared/models/class.model';
import { StudentContext } from 'src/app/shared/models/student.model';

@Component({
  selector: 'app-activity-summary-category',
  templateUrl: './activity-summary-category.component.html',
  styleUrls: ['./activity-summary-category.component.scss']
})

export class ActivitySummaryCategoryComponent implements OnInit, OnChanges {

  @Input() id: string = '';
  @Input() public students: StudentContext[] = [];
  @Input() public headerTitle: string = "";
  @Input() public description: string = "";
  @Input() public imageUrl: string = "";
  @Input() public numTotalStudents: number = 0;
  @Input() public classProducts!: ClassProducts;
  @Input() public showTakeAction: boolean = true;

  @ViewChild(MatExpansionPanel) expansionPanel: MatExpansionPanel | undefined;

  public isExpanded: boolean = false;
  public hasStudents: boolean = false;
  public panelStateText: string = "View Details";
  public panelStateGlyph: string = "glyph-chevron-expanded"

  constructor() { }

  async ngOnInit(): Promise<void> {
    this.hasStudents = this.students.length > 0;
  }

  ngOnChanges(): void {
    this.hasStudents = this.students.length > 0;
  }

  toggleExpansion(event: Event): boolean {
    this.expansionPanel?.toggle();
    this.isExpanded = !this.isExpanded;
    (event.target as HTMLElement)?.focus();
    return false;
  }

  onExpansionClose(): void {
    this.isExpanded = false;
  }

  onExpansionOpen(): void {
    this.isExpanded = true;
  }
}
