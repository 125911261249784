import { Component, Input, OnInit } from '@angular/core';
import { AdminHandlerService } from 'src/app/services/admin-handler/admin-handler.service';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';
import { LaunchControlService } from 'src/app/services/launch-control/launch-control.service';
import { ProductAppCodes } from 'src/app/services/product-info/product-info.service';
import { ProductKeys, ActionLink, Recommendation, RecommendationType } from 'src/app/shared/models/recommendation.model';
import { Link } from 'src/app/util/link/link';

@Component({
  selector: 'take-action-links-v2',
  templateUrl: './take-action-links.component.html',
  styleUrls: ['./take-action-links.component.scss'],
})
export class TakeActionLinksComponentV2 implements OnInit {

  @Input() public recommendation!: Recommendation;

  public freckleLink?: ActionLink;
  public nearpodLink?: ActionLink;
  public laliloLink?: ActionLink;
  public pendoId = '';

  constructor(
    public adminHandlerService: AdminHandlerService,
    public featureToggleService: FeatureToggleService,
    private launchControlService: LaunchControlService
  ) { }

  public ngOnInit(): void {
    this.setNearpodLink();
    this.setFreckleLink();
    this.setLaliloLink();

    switch (this.recommendation.recommendationType) {
      case RecommendationType.Appropriate:
        this.pendoId = 'pendo-reassign-skill';
        break;
      case RecommendationType.NeedsChallenge:
        this.pendoId = 'pendo-assign-next-skill';
        break;
      case RecommendationType.NeedsHelp:
        this.pendoId = 'pendo-assign-pre-requisite-skill';
        break;
    }
  }

  private async setNearpodLink() {
    this.nearpodLink = this.recommendation.productActionLinks[ProductKeys.Nearpod] ?? undefined;
  }

  private setFreckleLink() {
    this.freckleLink = this.recommendation.productActionLinks[ProductKeys.Freckle] ?? undefined;
  }

  private setLaliloLink() {
    this.laliloLink = this.recommendation.productActionLinks[ProductKeys.Lalilo] ?? undefined;
  }

  public navigateFreckleByLaunchControl(event: MouseEvent, link: Link){
    event.preventDefault();
    this.launchControlService.launchApp(ProductAppCodes.Freckle, link.url, true);
  }

  public navigateLaliloByLaunchControl(event: MouseEvent, link: Link){
    event.preventDefault();
    this.launchControlService.launchApp(ProductAppCodes.Lalilo, link.url, true);
  }
}
