<mat-card class="active-skill-card">
  <mat-card-header class="active-skill-card-header">
    <mat-card-title>
      <div class="active-skill-card-description">
        <app-skill-description
          [skillMetadata]="skillsPracticeCard.skillMetadata"
          [hasAmbiguousShortName]="skillsPracticeCard.hasAmbiguousShortName"
        ></app-skill-description>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="active-skill-card-content">
    <ng-container *ngFor="let category of categories">
      <mat-divider aria-hidden="true">
      </mat-divider>
      <div class="active-skill-category-container">
        <active-skill-category
          [skillMetadata]="skillsPracticeCard.skillMetadata"
          [hasAmbiguousShortName]="skillsPracticeCard.hasAmbiguousShortName"
          [categoryId]="category.info.id"
          [categoryName]="category.info.name"
          [categorySubText]="category.info.subText"
          [categoryIcon]="category.info.icon"
          [students]="category.students">
        </active-skill-category>
      </div>
    </ng-container>
    <ng-container *ngIf="emptyCategories.length > 0">
      <mat-divider aria-hidden="true">
      </mat-divider>
      <p class="no-students-text">No students in:</p>
      <ul class="empty-categories-container">
        <ng-template ngFor let-emptyCategory [ngForOf]=emptyCategories>
          <li class="empty-category-container">
            <img class='empty-category-image' [src]="emptyCategory.icon">
            <span>{{emptyCategory.name}}</span>
            <span class="empty-category-subtext">{{emptyCategory.subText}}</span>
          </li>
        </ng-template>
      </ul>
    </ng-container>
  </mat-card-content>
</mat-card>
