import { Component, Input, OnInit } from '@angular/core';
import { FreckleLinkGeneratorService } from 'src/app/services/link-generators/freckle-link-generator.service';
import { Student } from 'src/app/shared/models/student.model';
import { ActionableSkill, ProductKeys } from '../take-action-modal/take-action-modal.component';
import { Link } from 'src/app/util/link/link';
import { RecommendationType } from 'src/app/services/recommendation/RecommendationType';
import { AdminHandlerService } from 'src/app/services/admin-handler/admin-handler.service';

@Component({
  selector: 'take-action-links',
  templateUrl: './take-action-links.component.html',
  styleUrls: ['./take-action-links.component.scss'],
})
export class TakeActionLinksComponent implements OnInit {

  @Input() public skill: ActionableSkill = {
    skillMetadata: null,
    freckleRecType: RecommendationLinkType.None,
    nearpodRecType: RecommendationLinkType.None,
    recType: RecommendationType.None,
    rationale: '',
    hasAmbiguousShortName: false,
    productUrls: {[ProductKeys.Nearpod]: new Link(''), [ProductKeys.Freckle]: new Link('')}
  };
  @Input() public nearpodRecType: RecommendationLinkType = RecommendationLinkType.None;
  @Input() public freckleRecType: RecommendationLinkType = RecommendationLinkType.None;

  public freckleLink?: ActionLink;
  public nearpodLink?: ActionLink;

  private static NEARPOD_IMAGE_PATH = `../../../../../../assets/logos/nearpod_logo.svg`
  private static FRECKLE_IMAGE_PATH = `../../../../../../assets/logos/freckle_logo.svg`

  constructor(public adminHandlerService: AdminHandlerService) { }


  public ngOnInit(): void {
    this.setNearpodLink();
    this.setFreckleLink();
  }

  private async setNearpodLink() {
    const nearpodActions = {
      [RecommendationLinkType.None]: {
        link: this.skill.productUrls[ProductKeys.Nearpod],
        description: 'Search for lessons on this skill in Nearpod',
        name: 'Go to Nearpod',
        imageUrl: TakeActionLinksComponent.NEARPOD_IMAGE_PATH,
      },
      [RecommendationLinkType.Stuck]: {
        link: this.skill.productUrls[ProductKeys.Nearpod],
        description: 'Find a teacher resource for instruction on this skill',
        name: 'Lessons in Nearpod',
        imageUrl: TakeActionLinksComponent.NEARPOD_IMAGE_PATH,
      },
      [RecommendationLinkType.Okay]: {
        link: this.skill.productUrls[ProductKeys.Nearpod],
        description: 'Find a teacher resource for instruction on this skill',
        name: 'Lessons in Nearpod',
        imageUrl: TakeActionLinksComponent.NEARPOD_IMAGE_PATH,
      },
      [RecommendationLinkType.Proficient]: {
        link: this.skill.productUrls[ProductKeys.Nearpod],
        description: 'Find connected content in Nearpod',
        name: 'Related Lessons in Nearpod',
        imageUrl: TakeActionLinksComponent.NEARPOD_IMAGE_PATH,
      },
    } as Record<RecommendationLinkType, ActionLink>;

    this.nearpodLink = nearpodActions[this.nearpodRecType] ?? undefined;
  }

  private setFreckleLink() {
    const freckleActions = {
      [RecommendationLinkType.Stuck]: {
        link: this.skill.productUrls[ProductKeys.Freckle],
        description: 'Assign practice on prerequisite skills',
        name: 'Prerequisite Skill Practice in Freckle',
        imageUrl: TakeActionLinksComponent.FRECKLE_IMAGE_PATH
      },
      [RecommendationLinkType.Okay]: {
        link: this.skill.productUrls[ProductKeys.Freckle],
        description: 'Assign more practice on this skill',
        name: 'Skill Practice in Freckle',
        imageUrl: TakeActionLinksComponent.FRECKLE_IMAGE_PATH
      },
      [RecommendationLinkType.Proficient]: {
        link: this.skill.productUrls[ProductKeys.Freckle],
        description: 'Assign practice on the next skill',
        name: 'Skill Practice in Freckle',
        imageUrl: TakeActionLinksComponent.FRECKLE_IMAGE_PATH
      }
    } as Record<RecommendationLinkType, ActionLink>;

    this.freckleLink = freckleActions[this.freckleRecType] ?? undefined;

    // skipping DOK links for now
  }
}

export enum RecommendationLinkType {
  None = "none",
  Stuck = "stuck",
  Okay = "okay",
  Proficient = "proficient",
}

export interface ActionLink {
  link: Link;
  name: string;
  description: string;
  imageUrl: string;
}
