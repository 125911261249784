import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { Router } from '@angular/router';

export interface IntroAnimationStatus {
  enabled: boolean;
  complete: boolean;
};

@Injectable({
  providedIn: 'root'
})

export class IntroAnimationService {

  private hasSeenKey: string = "hasSeenIntroAnimation";

  private readonly defaultIntroAnimationStatus: IntroAnimationStatus = {
    enabled: false,
    complete: false
  }

  private _introAnimationEnabled = new BehaviorSubject(false);
  private _introAnimationEnabled$ = this._introAnimationEnabled.asObservable();

  private _leftNavAnimationStatus = new BehaviorSubject(this.defaultIntroAnimationStatus);
  private _leftNavAnimationStatus$ = this._leftNavAnimationStatus.asObservable();

  private _searchIntroAnimationStatus = new BehaviorSubject(this.defaultIntroAnimationStatus);
  private _searchIntroAnimationStatus$ = this._searchIntroAnimationStatus.asObservable();

  private _summaryIntroAnimationStatus = new BehaviorSubject(this.defaultIntroAnimationStatus);
  private _summaryIntroAnimationStatus$ = this._summaryIntroAnimationStatus.asObservable();

  constructor(
    private _localStorage: LocalStorageService,
    private _router: Router
  ) {}

  public kickOffIntroAnimations(): boolean {
    if (this._router.url != "/summary" && this._router.url != '/') {
      return false;
    }
    if (window.innerWidth < 1024) {
      return false;
    }
    if (this.introHasBeenSeen()) {
      return false;
    }
    this._introAnimationEnabled.next(true);
    return true;
  }

  private introHasBeenSeen(): boolean {
    const hasSeen = this._localStorage.getItem(this.hasSeenKey);

    // key doesn't exist or hasn't been set to "seen"
    if (!hasSeen || hasSeen != "seen") {
      this._localStorage.setItem(this.hasSeenKey, "seen");
      return false;
    }
    return true;
  }

  public get introAnimationEnabled$(): Observable<boolean> {return this._introAnimationEnabled$; };

  public get leftNavAnimationStatus$(): Observable<IntroAnimationStatus> { return this._leftNavAnimationStatus$; };

  public updateLeftNavAnimationStatus(animationStatus: IntroAnimationStatus) {
    this._leftNavAnimationStatus.next(animationStatus);
  }

  public get searchIntroAnimationStatus$(): Observable<IntroAnimationStatus> { return this._searchIntroAnimationStatus$; };

  public updateSearchIntroAnimationStatus(animationStatus: IntroAnimationStatus) {
    this._searchIntroAnimationStatus.next(animationStatus)
  }

  public get summaryIntroAnimationStatus$(): Observable<IntroAnimationStatus> { return this._summaryIntroAnimationStatus$; };

  public updateSummaryIntroAnimationStatus(animationStatus: IntroAnimationStatus) {
    this._summaryIntroAnimationStatus.next(animationStatus)
  }

}
