import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MyonLinkGeneratorV2Service {

  constructor() { }
  public getReportsPageDeepLink(): string {
    return 'MYON_REPORTS';
  }
}
