import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class LaunchControlService {


  constructor(
    private httpClient: HttpClient,
    private userService: UserService) { }

  public launchApp(appCode: string, deepLink?: string | any, isBlankTarget?: boolean) {
    const launchUrl = environment.launchControlServiceUrl + appCode;
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = launchUrl;
    if (isBlankTarget) {
      form.target = '_blank';
    }

    const homeUriField = document.createElement('input');
    homeUriField.type = 'hidden';
    homeUriField.name = "homeURI";
    homeUriField.value = this.userService.getHomeUri();
    form.appendChild(homeUriField);

    if (deepLink) {
      const deepLinkField = document.createElement('input');
      deepLinkField.type = 'hidden';
      deepLinkField.name = 'deepLink';
      if (typeof deepLink === 'string') {
        deepLinkField.value = encodeURIComponent(deepLink);
      } else {
        try {
          deepLinkField.value = JSON.stringify(deepLink);
        } catch (e) {
          console.error('Unable to encode launch deepLink, possibly circular object?', e);
        }
      }
      form.appendChild(deepLinkField);
    }

    document.body.appendChild(form);
    form.submit();
  }
}
