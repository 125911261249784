import { Component, Input } from '@angular/core';
import { StudentContext } from 'src/app/shared/models/student.model';
import { SkillPracticeCategory } from '../../../skills-practice-categories';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { SkillsPracticeTakeActionModalComponent } from './skills-practice-take-action-modal/skills-practice-take-action-modal.component';
import { SkillsPracticeTakeActionData } from './skills-practice-take-action-modal/skills-practice-take-action-data';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';
import { SkillsPracticeTakeActionModalComponentV2 } from './skills-practice-take-action-modal-v2/skills-practice-take-action-modal.component';
import { SkillsPracticeTakeActionDataV2 } from './skills-practice-take-action-modal-v2/skills-practice-take-action-data';
import { SkillMetadata } from 'src/app/shared/models/skill-metadata.model';
import { ProductIds } from 'src/app/services/product-info/product-info.service';

@Component({
  selector: 'active-skill-category',
  templateUrl: './active-skill-category.component.html',
  styleUrls: ['./active-skill-category.component.scss']
})
export class ActiveSkillCategoryComponent {
  @Input() skillMetadata!: SkillMetadata;
  @Input() hasAmbiguousShortName = false;
  @Input() categoryId: SkillPracticeCategory = SkillPracticeCategory.Inactive;
  @Input() categoryName: string = "";
  @Input() categorySubText: string = "";
  @Input() categoryIcon: string = "";
  @Input() students: StudentContext[] = [];
  public categoryJustStarting: boolean = false;
  public productIsLaliloAndLaliloTakeActionDisabled: boolean = false;
  public useRecommendationV2: boolean = false;

  constructor(
    public featureToggleService: FeatureToggleService,
    public breakpointObserver: BreakpointObserver,
    public matDialog: MatDialog) { }

  async ngOnInit(): Promise<void> {

    if (this.categoryId === SkillPracticeCategory.JustStarting) {
      this.categoryJustStarting = true;
    }

    if (this.skillMetadata.productName === ProductIds.Lalilo &&
      !(await this.featureToggleService.isTrueAsync('enable-lalilo-take-action-recommendations'))) {
      this.productIsLaliloAndLaliloTakeActionDisabled = true;
    }

    this.useRecommendationV2 = await this.featureToggleService.isTrueAsync('use-recommendation-v2');
  }

  public openTakeActionModal() {
    let maxWidth = '800px';
    let maxHeight = '800px';
    const isSmallWidthScreen = this.breakpointObserver.isMatched('(max-width: 800px)');
    const isSmallHeightScreen = this.breakpointObserver.isMatched('(max-height: 800px)');
    if (isSmallWidthScreen || isSmallHeightScreen) {
      maxWidth = (window.innerWidth - 20) + 'px';
      maxHeight = (window.innerHeight - 80) + 'px';
    }

    if (this.useRecommendationV2) {
      let skillsPracticeTakeActionData: SkillsPracticeTakeActionDataV2 = {
        skillMetadata: this.skillMetadata,
        hasAmbiguousShortName: this.hasAmbiguousShortName,
        skillPracticeCategory: this.categoryId,
        categoryName: this.categoryName,
        categoryIcon: this.categoryIcon,
        students: this.students
      };

      this.matDialog.open<SkillsPracticeTakeActionModalComponentV2, SkillsPracticeTakeActionDataV2>(SkillsPracticeTakeActionModalComponentV2, {
        maxWidth,
        maxHeight,
        restoreFocus: true,
        data: skillsPracticeTakeActionData
      });
    }

    else {
      let skillsPracticeTakeActionData: SkillsPracticeTakeActionData = {
        skillMetadata: this.skillMetadata,
        hasAmbiguousShortName: this.hasAmbiguousShortName,
        skillPracticeCategory: this.categoryId,
        categoryName: this.categoryName,
        categoryIcon: this.categoryIcon,
        students: this.students
      };

      this.matDialog.open<SkillsPracticeTakeActionModalComponent, SkillsPracticeTakeActionData>(SkillsPracticeTakeActionModalComponent, {
        maxWidth,
        maxHeight,
        restoreFocus: true,
        data: skillsPracticeTakeActionData
      });
    }
  }

  public categoryNameToClass() {
    return this.categoryName.toLowerCase().replace(/ /g, '-');
  }
}
