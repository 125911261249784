import { Injectable } from "@angular/core";
import { Category } from "src/app/shared/models/category.model";
import { StudentContext } from "src/app/shared/models/student.model";
import { SkillTests } from "src/app/util/skill-tests/skill-tests";
import { StarService } from "../star/star.service";
import { ReadingPractice } from "src/app/shared/models/reading-practice.model";
import { SubjectService } from "../subject/subject.service";
import { SubjectTypes } from "../subject/subject-types";
import { AppNavItemService } from "../app-nav/app-nav-item.service";
import { DateHelper } from "src/app/util/date-helper/date-helper";
import { FeatureToggleService } from "../feature-toggle/feature-toggle.service";

@Injectable({
  providedIn: 'root'
})
export class CategorizationService {

  private readonly arAppId: string = '1';

  constructor(private starService: StarService,
    private subjectService: SubjectService,
    private appNavItemService: AppNavItemService,
    private featureToggleService: FeatureToggleService) { }

  public async categorizeStudent(student: StudentContext): Promise<Category> {
    let subjectIsReading = this.subjectService.selectedSubject$.getValue() == SubjectTypes.READING;

    let hasNoPracticeData = student.skills.length == 0;
    let hasNoBenchmarkData = student.latestAssessment?.renaissanceIsProficient == null && student.latestAssessment?.districtIsProficient == null;
    let hasNoReadingData = student.readingPractices.length === 0;
    let lastAssessmentOver14DaysOld = (student.latestAssessment != null && !DateHelper.isWithinTwoWeeksOfToday(student.latestAssessment.completedDate)) || student.latestAssessment == null;

    // Students without both practice and assessment data are automatically put in Time To Practice
    // If selected subject is reading, students without reading data are also put in Time To Practice
    if (hasNoPracticeData && lastAssessmentOver14DaysOld && (!subjectIsReading || hasNoReadingData)) {
      if (await this.featureToggleService.isTrueAsync('enable-time-to-practice')) {
        return Category.TimeToPractice;
      }
    }

    // student with no practice and no benchmark data should be put in needs a helping hand
    if (hasNoPracticeData && hasNoBenchmarkData && (!subjectIsReading || hasNoReadingData)) {
      return Category.NeedsAHelpingHand;
    }

    // Student categories are based on 3 criteria:
    // Practice Accuracy
    let practiceAccuracy = this.getPracticeAccuracy(student);

    // Assessment Proficiency
    let assessmentProficiency = this.getAssessmentProficiency(student);

    // Reading Accuracy
    let readingAccuracy = Category.ReadyToBeChallenged;

    if (subjectIsReading) {
      readingAccuracy = this.getReadingAccuracy(student.readingPracticeSummary.averageQuizScore, student.readingPractices);
    }

    let criteria: Category[] = [
      practiceAccuracy,
      assessmentProficiency,
      readingAccuracy
    ];

    if (criteria.includes(Category.NeedsAHelpingHand)) {
      return Category.NeedsAHelpingHand;
    }
    if (criteria.includes(Category.WorkingAtAnAppropriateLevel)) {
      return Category.WorkingAtAnAppropriateLevel;
    }
    return Category.ReadyToBeChallenged;
  }

  private getReadingAccuracy(averageQuizScore: number, readingPractices: ReadingPractice[]): Category {
    if (SkillTests.hasStuckReadingPractice(averageQuizScore)) {
      return Category.NeedsAHelpingHand;
    }

    if (SkillTests.hasWorkingAtAppropriateLevelReadingPractice(averageQuizScore)) {
      return Category.WorkingAtAnAppropriateLevel;
    }

    // If AR access and no reading practice data has quiz score, categorize as appropriate level
    if (this.appNavItemService.hasApp([this.arAppId]) && readingPractices.length > 0 && averageQuizScore == null) {
      return Category.WorkingAtAnAppropriateLevel;
    }

    return Category.ReadyToBeChallenged;
  }

  private getPracticeAccuracy(student: StudentContext): Category {
    const effectiveMinimumAccuracy = SkillTests.getEffectiveMinSkillAccuracyRate(student.skills);
    const rawMinimumAccuracy = SkillTests.getRawMinSkillAccuracyRate(student.skills);

    let cappedCategory = Category.WorkingAtAnAppropriateLevel;
    if (SkillTests.isReadyForChallengeBasedOnAccuracyRate(rawMinimumAccuracy)) {
      cappedCategory = Category.ReadyToBeChallenged;
    }

    let isReadyForChallenge = SkillTests.isReadyForChallengeBasedOnAccuracyRate(effectiveMinimumAccuracy) && cappedCategory === Category.ReadyToBeChallenged;
    let studentHasNoPractice = student.skills.length === 0;

    if ((isReadyForChallenge && (SkillTests.hasPracticeWithThreeOrMoreItems(student.skills) || studentHasNoPractice)) ||
      !student.classProductUsage.hasPracticeActivity) {
      return Category.ReadyToBeChallenged;
    }
    if (SkillTests.isWorkingAtAppropriateLevelBasedOnAccuracyRate(effectiveMinimumAccuracy)) {
      return Category.WorkingAtAnAppropriateLevel;
    }
    return Category.NeedsAHelpingHand;
  }

  private getAssessmentProficiency(student: StudentContext): Category {
    if (student.classProductUsage.hasAssessmentActivity && student.latestAssessment != null) {
      if (this.starService.isStarCbm(student.latestAssessment?.productId)) {
        if (student.latestAssessment?.cbmBenchmarkCategory == null) {
          return Category.NeedsAHelpingHand;
        }
        if (this.starService.isCbmProficient(student.latestAssessment)) {
          return Category.ReadyToBeChallenged
        }
        else if (!DateHelper.isWithinTwoWeeksOfToday(student.latestAssessment?.completedDate)) {
          return Category.WorkingAtAnAppropriateLevel;
        }
        return Category.NeedsAHelpingHand;
      }

      if (
        (student.latestAssessment?.districtIsProficient == true) ||
        (student.latestAssessment?.districtIsProficient == null && student.latestAssessment?.renaissanceIsProficient == true)
      ) {
        return Category.ReadyToBeChallenged;
      }

      if (
        (student.latestAssessment?.districtIsProficient == false) ||
        (student.latestAssessment?.districtIsProficient == null && student.latestAssessment?.renaissanceIsProficient == false)
      ) {
        if (!DateHelper.isWithinTwoWeeksOfToday(student.latestAssessment?.completedDate))
          return Category.WorkingAtAnAppropriateLevel;
      }
      return Category.NeedsAHelpingHand;
    }
    return Category.ReadyToBeChallenged;
  }
}
