import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class PendoService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private userService: UserService) { }

  initialize() {
    this.userService.isLoggedIn().then(() => {
      const window = this.document.defaultView as any;
      // ambient pendo reference is available to us see index.html
      // login to pendo -> Subscription Settings -> Install Settings for documentation
      // type pendo.validateInstall() in the console to validate install
      if (window.pendo) {
        console.log('Pendo initialized');
        const pendo = window.pendo;
        pendo.initialize({
          visitor: {
            id: this.userService.getUserId(),
            userType: this.userService.getNextRole().toUpperCase()
          },
          account: {
            id: this.userService.getClientId()
          }
        });
      }
    })
  }
}
