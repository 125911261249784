<div class="skill-description-container">
  <span class="skill-title">
    <h2 *ngIf="isModalHeading" tabindex="0" [id]="getElementId()" class="skill-description clamp"
      [innerHTML]="getDescriptionContent()"></h2>
  
    <h4 *ngIf="!isModalHeading" tabindex="0" [id]="getElementId()" class="skill-description clamp"
      [innerHTML]="getDescriptionContent()"></h4>
  </span>
  
  <ng-container>
    <span class="product-subtext">
      {{getProductName()}}
    </span>
  </ng-container>
  <button type="button" class="disambiguate-button ren-link"
    *ngIf="hasAmbiguousShortName && !doShowFullName"
    (click)="showFullDescription()"
  >(Full description)</button>
  <button type="button" class="ren-link see-more"
    *ngIf="doneLoading && isTextClamped()"
    (click)="expandClampedDescription()"
  >See more</button>
</div>
