import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { NewRelicInstrumentationService } from '../new-relic-instrumentation/new-relic-instrumentation.service';
import { firstValueFrom, take, throwError } from 'rxjs';
import { AppNavV2 } from 'src/app/shared/models/app-nav-v2.model';
import { FeatureToggleService } from '../feature-toggle/feature-toggle.service';
import { Future } from 'src/app/util/future';

@Injectable({
  providedIn: 'root'
})
export class AvailableAppNavV2Service {
  private licensedApplicationsMap = new Map<string, AppNavV2[]>();
  private licensedApplications: AppNavV2[] = [];
  private isDegraded = false;
  private _isLoaded: Future<boolean> = new Future<boolean>();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private newRelicService: NewRelicInstrumentationService,
    private featureToggleService: FeatureToggleService,
  ) { }

  public async loadAppNavV2() {

    if (await this.featureToggleService.isTrueAsync("tj-sabotage-force-app-nav-load-fail")) {
      this.isDegraded = true;
      this.newRelicService.recordAppNavDegradedInfo('appnav-sabotage-toggle');
      this._isLoaded.resolve(true);
      return;
    }

    if (this.userService.getSkipNavAccessCheck()) {
      this._isLoaded.resolve(true);
      return;
    }

    let url = this.userService.getAppNavV2Url();
    let headers = new HttpHeaders({ 'access-token': this.userService.getRawJwt() ?? '' });

    if (this.licensedApplicationsMap.has(url)) {
      this.licensedApplications = this.licensedApplicationsMap.get(url) as AppNavV2[];
      this._isLoaded.resolve(true);
      return;
    }

    try {
      
      var response = await firstValueFrom(this.http.get(url, {
        headers: headers
      }).pipe(take(1)));

      this.licensedApplications = response as AppNavV2[];
      this.licensedApplicationsMap.set(url, this.licensedApplications!);
    }
    catch (err) {
      let httpError = err as HttpErrorResponse;
      this.newRelicService.recordAppNavDegradedInfo(`appnav-v2-api-error-${httpError.status}`);
      this.isDegraded = true;
    }

    this._isLoaded.resolve(true);
  }

  getAppNavV2() {
    return this.licensedApplications;
  }

  getIsDegraded() {
    return this.isDegraded;
  }

  isLoaded(): Promise<boolean> {
    return this._isLoaded.promise;
  }
}

