import { Injectable } from '@angular/core';
import { AppNavV2 } from 'src/app/shared/models/app-nav-v2.model';
import { ProductAppCodes, ProductAppDisplayNames, ProductAppImagePrefix } from '../product-info/product-info.service';
import { AppNavV2ItemConfigurationService } from './app-nav-v2-item-configuration.service';
import { AppNavItem, AppNavItemIds } from 'src/app/shared/models/app-nav-item.model';
import { AppNavV2ReportService } from './app-nav-v2-report.service';
import { AppNavV2ItemService } from './app-nav-v2-item.service';

@Injectable({
  providedIn: 'root'
})
export class AppNavV2HelperService {
  public appNavV2: AppNavV2[] = [];
  constructor
    (
      private _appNavV2ItemConfigurationService: AppNavV2ItemConfigurationService,
      private _appNavV2ReportService: AppNavV2ReportService,
      private _appNavV2ItemService: AppNavV2ItemService
    ) { }

  public initialize(appNavV2Data: AppNavV2[]) {
    this.appNavV2 = appNavV2Data;
  }

  public getAvailableV2SearchReportItems(searchReportItems: AppNavV2[]) {
    let items = this._appNavV2ReportService.getAppNavV2ReportItems(searchReportItems);
    if (this.isLicensedAppCode(ProductAppCodes.Freckle) == true) {
      let freckleItems = this._appNavV2ItemConfigurationService.getFreckleSearchReports();
      items.push(...freckleItems);
    }
    if (this.isLicensedAppCode(ProductAppCodes.Lalilo) == true) {
      let laliloItems = this._appNavV2ItemConfigurationService.getLaliloSearchReports();
      items.push(...laliloItems);
    }
    if(this.isLicensedAppCode(ProductAppCodes.Myon) == true){
      let myonItems = this._appNavV2ItemConfigurationService.getMyonSearchReports();
      items.push(...myonItems);
    }
    return items;
  }

  public getMyDataNavsV2(myReports: AppNavV2[]) {
    let MYDATA_NAVS_REPORTS: AppNavItem = {
      id: AppNavItemIds.REPORTS,
      displayName: 'Reports',
      imagePrefix: 'reports_logo',
      children: this.getMyDataReportChildren(myReports)
    };

    let MYDATA_NAVS: AppNavItem[] =
      [
        MYDATA_NAVS_REPORTS
      ];

    return MYDATA_NAVS;
  }

  private getMyDataReportChildren(myReports: AppNavV2[]) {
    let myDataReportChildren = this._appNavV2ReportService.getAppNavV2ReportItems(myReports);
    if (this.isLicensedAppCode(ProductAppCodes.StarPhonics) == true) {
      myDataReportChildren.push(this._appNavV2ItemConfigurationService.getStarPhonicsReports());
    }
    if (this.isLicensedAppCode(ProductAppCodes.Freckle) == true) {
      myDataReportChildren.push(this._appNavV2ItemConfigurationService.getFreckleReports());
    }
    if (this.isLicensedAppCode(ProductAppCodes.Myon) == true) {
      myDataReportChildren.push(this._appNavV2ItemConfigurationService.getMyONReports());
    }
    if (this.isLicensedAppCode(ProductAppCodes.Lalilo) == true) {
      myDataReportChildren.push(this._appNavV2ItemConfigurationService.getLaliloReports());
    }
    return this.orderByReport(myDataReportChildren);
  }

  private orderByReport(myReports: AppNavItem[]) {
    let reports: AppNavItem[] = [];
    let starReadingReport = myReports.find(item => item.appCode === ProductAppCodes.StarReading);
    let starCbmReadingReport = myReports.find(item => item.appCode === ProductAppCodes.StarCbmReading);
    let starPhonicsReport = myReports.find(item => item.appCode === ProductAppCodes.StarPhonics);
    let arReport = myReports.find(item => item.appCode === ProductAppCodes.AR);
    let freckelReport = myReports.find(item => item.appCode === ProductAppCodes.Freckle);
    let myOnReport = myReports.find(item => item.appCode === ProductAppCodes.Myon);
    let lalioReport = myReports.find(item => item.appCode === ProductAppCodes.Lalilo);
    let adminReport = myReports.find(item => item.appCode === ProductAppCodes.AdminReports);
    let myCreatedReports = myReports.find(item => item.appCode === ProductAppCodes.MyCreatedReports);
    let dataSharingReports = myReports.find(item => item.appCode === ProductAppCodes.DataSharingReports);
    let customReports = myReports.find(item => item.appCode === ProductAppCodes.CustomReports);

    if (starReadingReport) {
      reports.push(starReadingReport);
    }
    if (starCbmReadingReport) {
      reports.push(starCbmReadingReport);
    }
    if (starPhonicsReport) {
      reports.push(starPhonicsReport);
    }
    if (arReport) {
      reports.push(arReport);
    }
    if (freckelReport) {
      reports.push(freckelReport);
    }
    if (myOnReport) {
      reports.push(myOnReport);
    }
    if (lalioReport) {
      reports.push(lalioReport);
    }
    if (adminReport) {
      reports.push(adminReport);
    }
    if (myCreatedReports) {
      reports.push(myCreatedReports);
    }
    if (dataSharingReports) {
      reports.push(dataSharingReports);
    }
    if (customReports) {
      reports.push(customReports);
    }

    return reports;
  }

  public getMyAppNavsV2(appNavV2: AppNavV2[]) {
    let MY_APPNAV_AR: AppNavItem = {
      id: AppNavItemIds.AR,
      displayName: ProductAppDisplayNames.AR,
      imagePrefix: ProductAppImagePrefix.AR,
      children: this._appNavV2ItemService.getAppNavV2Items(appNavV2, [ProductAppCodes.AR])
    };

    let MY_APPNAV_FRECKLE: AppNavItem = this._appNavV2ItemService.getAppNavV2Item(appNavV2, ProductAppCodes.Freckle);
    MY_APPNAV_FRECKLE.imagePrefix = ProductAppImagePrefix.Freckle;

    let MY_APPNAV_LALILO: AppNavItem = this._appNavV2ItemService.getAppNavV2Item(appNavV2, ProductAppCodes.Lalilo);
    MY_APPNAV_LALILO.imagePrefix = ProductAppImagePrefix.Lalilo;

    let MY_APPNAV_MYON: AppNavItem = this._appNavV2ItemService.getAppNavV2Item(appNavV2, ProductAppCodes.Myon);
    MY_APPNAV_MYON.imagePrefix = ProductAppImagePrefix.Myon;

    let MYAPPS_NAVS_NEARPOD: AppNavItem = {
      displayName: 'Nearpod',
      imagePrefix: 'nearpod_logo',
      url: 'https://nearpod.com/?referrer=ren_next',
    };

    let MY_APPNAV_SCHOOLZILLA: AppNavItem = this._appNavV2ItemService.getAppNavV2Item(appNavV2, ProductAppCodes.Schoolzilla);
    MY_APPNAV_SCHOOLZILLA.imagePrefix = ProductAppImagePrefix.Schoolzilla;

    let MY_APPNAV_STAR: AppNavItem = {
      id: AppNavItemIds.STAR,
      displayName: ProductAppDisplayNames.Star,
      imagePrefix: ProductAppImagePrefix.Star,
      children: this._appNavV2ItemService.getAppNavV2Items(appNavV2, [ProductAppCodes.StarReading, ProductAppCodes.StarEarlyLiteracy,
      ProductAppCodes.StarMath, ProductAppCodes.StarCbmReading, ProductAppCodes.StarCbmMath, ProductAppCodes.StarCbmLectura,
      ProductAppCodes.StarReadingSpanish, ProductAppCodes.StarPreschool, ProductAppCodes.StarPhonics,
      ProductAppCodes.StarCustom, ProductAppCodes.Saebrs, ProductAppCodes.Planner])
    };

    let MY_APPNAV_RENU: AppNavItem = this._appNavV2ItemService.getAppNavV2Item(appNavV2, ProductAppCodes.RENU);
    MY_APPNAV_RENU.imagePrefix = ProductAppImagePrefix.RENU;
    MY_APPNAV_RENU.id = AppNavItemIds.RENU;

    let MYAPPS_NAVS: AppNavItem[] = [];
    if (MY_APPNAV_AR.children) {
      MYAPPS_NAVS.push(MY_APPNAV_AR);
    }
    if (MY_APPNAV_FRECKLE.displayName != "") {
      MYAPPS_NAVS.push(MY_APPNAV_FRECKLE);
    }
    if (MY_APPNAV_LALILO.displayName != "") {
      MYAPPS_NAVS.push(MY_APPNAV_LALILO);
    }
    if (MY_APPNAV_MYON.displayName != "") {
      MYAPPS_NAVS.push(MY_APPNAV_MYON);
    }
    MYAPPS_NAVS.push(MYAPPS_NAVS_NEARPOD);
    if (MY_APPNAV_SCHOOLZILLA.displayName != "") {
      MYAPPS_NAVS.push(MY_APPNAV_SCHOOLZILLA);
    }
    if (MY_APPNAV_STAR.children) {
      MYAPPS_NAVS.push(MY_APPNAV_STAR);
    }
    if (MY_APPNAV_RENU.displayName != "") {
      MYAPPS_NAVS.push(MY_APPNAV_RENU);
    }

    return MYAPPS_NAVS;
  }

  public getAppNavDataByTag(tagName: string): AppNavV2[] {
    return this.appNavV2.filter(nav => nav.tags?.find(t => t == tagName));
  }

  private isLicensedAppCode(appCode: string): boolean {
    let item = this.appNavV2.find(item => item.appCode == appCode);
    return item != null ? true : false
  }
}
