import { Injectable } from '@angular/core';
import { Student, ClassProductUsage } from 'src/app/shared/models/student.model';
import { SkillMetadataByStandardSetsRequest, SkillMetadataForLaliloRequest } from '../../graphql/skill-metadata-request';
import { BaseRecommendationSkill, ProductKeys, Recommendation } from '../../../shared/models/recommendation.model';
import { ClassesService } from '../../classes/classes.service';
import { GraphqlService } from '../../graphql/graphql.service';
import { SubjectTypes } from '../../subject/subject-types';
import { FreckleActionRecommendationService } from './freckle-action/freckle-action-recommendation.service';
import { NearpodActionRecommendationService } from './nearpod-action/nearpod-action-recommendation.service';
import { RecommendationType } from '../../recommendation/RecommendationType';
import { LaliloActionRecommendationService } from './lalilo-action/lalilo-action-recommendation.service';
import { FeatureToggleService } from '../../feature-toggle/feature-toggle.service';
import { OverlapSkillRecommendationService } from '../overlap-skill/overlap-skill-recommendation.service';

@Injectable({
  providedIn: 'root'
})
export class ActionRecommendationService {

  constructor(
    private classesService: ClassesService,
    private graphqlService: GraphqlService,
    private freckleActionRecommendationService: FreckleActionRecommendationService,
    private nearpodActionRecommendationService: NearpodActionRecommendationService,
    private laliloActionRecommendationService: LaliloActionRecommendationService,
    private overlapSkillRecommendationService: OverlapSkillRecommendationService,
    private featureToggleService: FeatureToggleService
  ) { }

  public async getActionsForBaseRecommendationSkill(skill: BaseRecommendationSkill, students: Student[], classProductUsage: ClassProductUsage): Promise<Recommendation | null> {
    let standardSets = await this.classesService.getStandardSetsForClass();
    let standardSetIdForSubject = standardSets.filter(x => this.mapStandardSetSubjectToSubject(x.subject) === skill.subject)?.[0]?.standardSetId;
    let standardSetIds = [];

    if (standardSetIdForSubject) {
      standardSetIds.push(standardSetIdForSubject);
    }

    // Freckle prereq skill metadata
    let frecklePrereqMetadata = null;

    if (skill.subject === SubjectTypes.READING && skill.recommendationType === RecommendationType.NeedsHelp && skill.prerequisiteSkillId) {
      let prereqSkillMetadataRequest: SkillMetadataByStandardSetsRequest = {
        renaissanceSkillId: skill.prerequisiteSkillId,
        standardSetIds: standardSetIds
      }
      let prereqskillMetadataByStandardSetsProductsResponse = await this.graphqlService.getSkillMetadataByStandardSetsMultipleIdFormats(prereqSkillMetadataRequest);
      let prereqskillMetadataByStandardSetsProducts = prereqskillMetadataByStandardSetsProductsResponse.skillMetadata;
      frecklePrereqMetadata = prereqskillMetadataByStandardSetsProducts.find( x => x.productName === ProductKeys.Freckle) ?? null;
    }

    // Product skill metadata
    let skillMetadataRequest: SkillMetadataByStandardSetsRequest = {
      renaissanceSkillId: skill.skillId,
      standardSetIds: standardSetIds
    }

    let skillMetadataByStandardSetsProductsResponse = await this.graphqlService.getSkillMetadataByStandardSetsMultipleIdFormats(skillMetadataRequest);
    let skillMetadataByStandardSetsProducts = skillMetadataByStandardSetsProductsResponse.skillMetadata;

    let freckleMetadata = skillMetadataByStandardSetsProducts.find( x => x.productName === ProductKeys.Freckle) ?? null;
    let nearpodMetadata = skillMetadataByStandardSetsProducts.find( x => x.productName === ProductKeys.Nearpod) ?? null;
    let starMetadata = skillMetadataByStandardSetsProducts.find( x => x.productName === ProductKeys.Star) ?? null; // TODO: Should rename this in the database to LPS (LearningProgressionService) instead of STAR

    let displayedSkillMetadata = freckleMetadata ?? starMetadata ?? nearpodMetadata ?? null;

    /* Query Lalilo skill metadata
    Separate query as lalilo does not have standardsets and uses contentActivityId, not renaissanceSkillId */

    let laliloMetadata = null;
    if (await this.featureToggleService.isTrueAsync('enable-lalilo-take-action-recommendations')) {

      // Query Lalilo if Freckle skill exists and is not an overlap skill
      const isFreckleLaliloOverlapSkill = !!freckleMetadata && !this.overlapSkillRecommendationService.isFreckleLaliloOverlapSkill(freckleMetadata.contentActivityId);

      if (!isFreckleLaliloOverlapSkill) {
        let laliloMetadataRequest: SkillMetadataForLaliloRequest = {
          contentActivityId: skill.skillId
        }
        let laliloMetadataResponse = (await this.graphqlService.getSkillMetadataForLalilo(laliloMetadataRequest)).skillMetadata;
        laliloMetadata = laliloMetadataResponse.find( x => x.productName === ProductKeys.Lalilo) ?? null;

        displayedSkillMetadata = laliloMetadata ?? freckleMetadata ?? starMetadata ?? nearpodMetadata ?? null;
      }
    }

    let recommendation = null;

    if (displayedSkillMetadata) {
      recommendation = {
        skillMetadata: displayedSkillMetadata,
        rationale: '',
        recommendationType: skill.recommendationType,
        productActionLinks: {}
      } as Recommendation;

      if (skill.practiceProductType) {
        recommendation.practiceProductType = skill.practiceProductType;
      }

      // Freckle take action link
      let freckleActionLink = await this.freckleActionRecommendationService.getFreckleActionForRecommendation(skill, freckleMetadata, frecklePrereqMetadata, students, classProductUsage.hasPracticeProductAssigned);

      if (freckleActionLink) {
        recommendation.productActionLinks[ProductKeys.Freckle] = freckleActionLink;
      }

      // Nearpod take action link
      let nearpodActionLink = await this.nearpodActionRecommendationService.getNearpodActionForRecommendation(skill, nearpodMetadata, freckleMetadata, laliloMetadata);

      if (nearpodActionLink) {
        recommendation.productActionLinks[ProductKeys.Nearpod] = nearpodActionLink;
      }

      // Lalilo take action link
      if (await this.featureToggleService.isTrueAsync('enable-lalilo-take-action-recommendations')) {
        let laliloActionLink = await this.laliloActionRecommendationService.getLaliloActionForRecommendation(skill, laliloMetadata, freckleMetadata, students);

        if (laliloActionLink) {
          recommendation.productActionLinks[ProductKeys.Lalilo] = laliloActionLink;
        }
      }

      recommendation.recommendationAudit = skill.recommendationAudit;
    }

    return recommendation;
  }

  private mapStandardSetSubjectToSubject(standardSetSubject: string): string {
    // TODO: once we update the database values for the teacher standard we can remove this call
    switch (standardSetSubject) {
      case 'math': {
        return SubjectTypes.MATH;
      }
      case 'ela': {
        return SubjectTypes.READING;
      }
    }
    return standardSetSubject;
  }
}
