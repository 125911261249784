import { Component, Input, OnInit } from '@angular/core';
import { SkillsPracticeCard } from 'src/app/shared/models/skills-practice-card';
import { SkillPracticeCategory } from '../../skills-practice-categories';
import { StudentContext } from 'src/app/shared/models/student.model';

export interface CategoryInfo {
  id: SkillPracticeCategory;
  name: string;
  subText: string;
  icon: string;
}
export interface Category {
  info: CategoryInfo;
  students: StudentContext[]
}

@Component({
  selector: 'active-skill-card',
  templateUrl: './active-skill-card.component.html',
  styleUrls: ['./active-skill-card.component.scss']
})
export class ActiveSkillCardComponent implements OnInit {

  @Input() public skillsPracticeCard: SkillsPracticeCard = {
    skillMetadata: {
      contentActivityId: '',
      productName: '',
      productUrl: '',
      renaissanceSkillId: '',
      skillIsFocus: false,
      skillName: '',
      skillProgressionOrder: 0,
      skillShortName: '',
      standardId: '',
      standardProgressionOrder: 0,
    },
    hasAmbiguousShortName: false,
    justStartingStudents: [],
    stuckStudents: [],
    practiceMakesPerfectStudents: [],
    readyForChallengeStudents: [],
  };

  CategoryStarting: CategoryInfo = {
    id: SkillPracticeCategory.JustStarting,
    name: "Just Starting",
    subText: "(<3 items)",
    icon: "/assets/skills_practice_just_starting.svg"
  }
  CategoryStuck: CategoryInfo = {
    id: SkillPracticeCategory.Stuck,
    name:  "Stuck",
    subText:"(<50%)",
    icon: "/assets/skills_practice_stuck.svg"
  }

  CategoryPractice: CategoryInfo = {
    id: SkillPracticeCategory.PracticeMakesPerfect,
    name: "Practice Makes Perfect",
    subText: "(50% - 80%)",
    icon: "/assets/skills_practice_practice_makes_perfect.svg"
  }

  CategoryChallenge: CategoryInfo = {
    id: SkillPracticeCategory.ReadyForChallenge,
    name:  "Ready for a Challenge",
    subText: "(>80%)",
    icon: "/assets/skills_practice_ready_for_a_challenge.svg"
  }

  public categories: Category[] = [];
  public emptyCategories: CategoryInfo[] = [];

  public ngOnInit() {
    this.setCategories(this.skillsPracticeCard);
  }

  private setCategories(skillsPracticeCard: SkillsPracticeCard) {
    this.emptyCategories = [];
    if (skillsPracticeCard.justStartingStudents.length !== 0) {
      this.categories.push({
        students: this.skillsPracticeCard.justStartingStudents,
        info: this.CategoryStarting
      });
    } else {
      this.emptyCategories.push(this.CategoryStarting);
    }

    if (skillsPracticeCard.stuckStudents.length !== 0) {
      this.categories.push({
        students: this.skillsPracticeCard.stuckStudents,
        info: this.CategoryStuck
      });
    } else {
      this.emptyCategories.push(this.CategoryStuck);
    }

    if (skillsPracticeCard.practiceMakesPerfectStudents.length !== 0) {
      this.categories.push({
        students: this.skillsPracticeCard.practiceMakesPerfectStudents,
        info: this.CategoryPractice
      });
    } else {
      this.emptyCategories.push(this.CategoryPractice);
    }

    if (skillsPracticeCard.readyForChallengeStudents.length !== 0) {
      this.categories.push({
        students: this.skillsPracticeCard.readyForChallengeStudents,
        info: this.CategoryChallenge
      });
    } else {
      this.emptyCategories.push(this.CategoryChallenge);
    }
  }
}
