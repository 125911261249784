import { Injectable } from '@angular/core';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { environment } from 'src/environments/environment';
import { Future } from '../../util/future';
import { NewRelicInstrumentationService } from 'src/app/services/new-relic-instrumentation/new-relic-instrumentation.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {
  private _client: LDClient.LDClient | null = null;
  private _isReady = new Future<boolean>();
  private _isInitializing = false;

  /**
   * Observable that indicates that the client has been initialized with latest toggle values
   *
   * This could be used with an angular Route [Guard](https://angular.io/api/router/CanActivate) or Route [Resolver](https://angular.io/api/router/Resolve) if you must have the toggle initialized
   */
  public isReady = this._isReady.promise;

  constructor(
    private newRelicInstrumentationService: NewRelicInstrumentationService
  ) { }

  /**
   * Returns the value of a given flag.
   *
   * If the logic must be 100% up to date check `isReady` before checking the flag.
   * @param flagName
   * @param defaultValue by default `false` but it is the default value for the flag
   * @returns boolean
   */
  isTrue(flagName: string, defaultValue = false): boolean {
    return this._client?.variation(flagName, defaultValue) ?? defaultValue;
  }

  async isTrueAsync(flagName: string, defaultValue = false): Promise<boolean> {
    await this.isReady;
    return this.isTrue(flagName, defaultValue);
  }

  /**
   * Initializes the LaunchDarkly client with the given clientId
   *
   * @param clientId
   */
  public async initialize(clientId: string): Promise<void> {
    /**
     * If the client is already initialized or is initializing, we should not initialize it again
     */
    if (!this._client && !this._isInitializing) {
      this._isInitializing = true
      const context = { kind: 'user', key: clientId, }

      try {
        const client = LDClient.initialize(environment.launchdarklyClientId, context)
        await client.waitForInitialization()

        /**
         * It is important to set the client after initialization because the client is not
         * ready until it has been initialized. If we set the client before initialization,
         * the client object will exists but it will not be ready
         */
        this._client = client
      }
      catch (error) {
        // Try using the relay proxy if the client fails to initialize
        const options: LDClient.LDOptions = {
          streamUrl: environment.ldRelayProxyUrl,
          baseUrl: environment.ldRelayProxyUrl,
          eventsUrl: environment.ldRelayProxyUrl
        };

        const client = LDClient.initialize(environment.launchdarklyClientId, context, options)
        await client.waitForInitialization()
        this._client = client

        // Log the relay proxy event in New Relic
        this.newRelicInstrumentationService.recordLaunchDarklyRelayProxyClient(clientId);
      }
    }

    this._isReady.resolve(true)
  }
}
