import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SkillsPracticeTakeActionDataV2 } from './skills-practice-take-action-data';
import { Recommendation } from 'src/app/shared/models/recommendation.model';
import { RecommendationServiceV2 } from 'src/app/services/recommendation-v2/recommendation-v2.service';
import { SkillPracticeCategory } from 'src/app/home/skills-practice/skills-practice-categories';
import { ProductIds } from 'src/app/services/product-info/product-info.service';
import { OverlapSkillRecommendationService } from 'src/app/services/recommendation-v2/overlap-skill/overlap-skill-recommendation.service';


@Component({
  selector: 'app-skills-practice-take-action-modal-2',
  templateUrl: './skills-practice-take-action-modal.component.html',
  styleUrls: ['./skills-practice-take-action-modal.component.scss']
})

export class SkillsPracticeTakeActionModalComponentV2 {
  public recommendation!: Recommendation;
  public hasLoaded = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SkillsPracticeTakeActionDataV2,
    public ref: MatDialogRef<SkillsPracticeTakeActionModalComponentV2>,
    private recommendationService: RecommendationServiceV2,
    private overlapSkillRecommendationService: OverlapSkillRecommendationService) {
  }

  async ngOnInit() {
    this.recommendation = await this.getRecommendation(this.data);
    this.hasLoaded = true;
  }

  async getRecommendation(data: SkillsPracticeTakeActionDataV2) {
    return await this.recommendationService.getRecommendationForStudentsWithSkill(data.students, data.skillMetadata);
  }

  getStudentCountText() {
    let numStudents = this.data.students.length;

    if (numStudents == 1) {
      return `(${numStudents} student)`;
    }

    else {
      return `(${numStudents} students)`
    }
  }

  public close(event: Event) {
    event.preventDefault();
    this.ref.close();
  }

  public skillPracticeCategoryIsReadyForChallengeAndProductNotLaliloAndNotOverlapSkill() {
    if (this.data.skillPracticeCategory === SkillPracticeCategory.ReadyForChallenge &&
      this.data.skillMetadata.productName != ProductIds.Lalilo &&
     !this.overlapSkillRecommendationService.isFreckleLaliloOverlapSkill(this.data.skillMetadata.contentActivityId)) {
      return true;
    }
    return false;
  }

  public getReadyForChallengeHeaderText() {
    let readyForChallengeHeaderText = 'ready to move on to the next skill:';

    let numStudents = this.data.students.length;
    if (numStudents == 1) {
      return 'This student is ' + readyForChallengeHeaderText;
    }

    else {
      return 'These students are ' + readyForChallengeHeaderText;
    }
  }

  public skillIsOverlap(): boolean {
    if (this.overlapSkillRecommendationService.isFreckleLaliloOverlapSkill(this.data.skillMetadata.contentActivityId)) {
      return true;
    }
    return false;
  }

  public getOverlapSkillHeaderText(): string {
    let numStudents = this.data.students.length;

    let studentString = numStudents == 1 ? 'this student' : 'these students';
    let overlapHeaderText = `Based on the skill grade-level, we recommend having ${studentString} switch to practicing in Lalilo.`;

    if (this.data.skillPracticeCategory === SkillPracticeCategory.Stuck) {
      let stuckStudentString = numStudents == 1 ? 'This student is' : 'These students are'
      let stuckOverlapHeaderText = `${stuckStudentString} struggling on this skill in Freckle. `
      overlapHeaderText = stuckOverlapHeaderText + overlapHeaderText;
    }

    return overlapHeaderText;
  }
}
