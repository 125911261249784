import { Injectable } from '@angular/core';
import { BaseRecommendationSkill, Recommendation, RecommendationType } from 'src/app/shared/models/recommendation.model';
import { ClassProductUsage, StudentContext } from 'src/app/shared/models/student.model';
import { SkillTests } from 'src/app/util/skill-tests/skill-tests';
import { ProductAppDisplayNames, ProductIds, ProductTypeGroups } from '../../product-info/product-info.service';
import { Skill } from 'src/app/shared/models/skill.model';
import { SkillAggregate } from 'src/app/util/skill-aggregate/skill-aggregate';
import { ActionRecommendationService } from '../action/action-recommendation.service';
import { FeatureToggleService } from '../../feature-toggle/feature-toggle.service';

@Injectable({
  providedIn: 'root'
})
export class LaliloRecommendationService {
  private readonly FINAL_LALILO_LESSON_ID = '1017';

  constructor(
    private actionRecommendationService: ActionRecommendationService,
    private featureToggleService: FeatureToggleService
  ) { }

  public async getLaliloSkillRecommendation(selectedStudent: StudentContext, allStudents: StudentContext[]): Promise<Recommendation | null> {
    let laliloSkillRecommendation: Recommendation | null = null;

    // Selected student must have Lalilo activity
    if (SkillTests.studentHasPracticeActivity(selectedStudent) && SkillTests.studentHasLaliloActivity(selectedStudent)) {
      // Get highlighted Lalilo skill for student
      let laliloSkills = selectedStudent.skills.filter(skill => skill.productId === ProductIds.Lalilo);
      let laliloBaseRecommendationSkill = await this.getLaliloBaseRecommendationSkill(laliloSkills);

      if (laliloBaseRecommendationSkill) {
        if (laliloBaseRecommendationSkill.recommendationType === RecommendationType.NeedsHelp) {
          let stuckStudents = SkillTests.getStuckStudentsForSkill(allStudents, laliloBaseRecommendationSkill.skillId);
          laliloSkillRecommendation = await this.actionRecommendationService.getActionsForBaseRecommendationSkill(laliloBaseRecommendationSkill, stuckStudents, selectedStudent.classProductUsage);
        }
        else {
          laliloSkillRecommendation = await this.actionRecommendationService.getActionsForBaseRecommendationSkill(laliloBaseRecommendationSkill, [selectedStudent], selectedStudent.classProductUsage);
        }
        if (laliloSkillRecommendation) {
          let rationale = this.getRationale(laliloBaseRecommendationSkill, selectedStudent);
          laliloSkillRecommendation.rationale = rationale;
          laliloSkillRecommendation.nudgeText = { skillsPractice: rationale };
        }
      }
    }

    if (laliloSkillRecommendation != null && await this.featureToggleService.isTrueAsync('enable-updated-no-recommendation')) {
      laliloSkillRecommendation.skillsPracticeProducts = [ProductAppDisplayNames.Lalilo];
    }

    return laliloSkillRecommendation;
  }

  public async getLaliloSkillRecommendationForStudentsWithSkill(students: StudentContext[], skill: Skill, classProductUsage: ClassProductUsage): Promise<Recommendation | null> {
    let laliloSkillRecommendation: Recommendation | null = null;

    let laliloBaseRecommendationSkill = await this.getLaliloBaseRecommendationSkill([skill]);

    if (laliloBaseRecommendationSkill) {

      // Get take action links
      laliloSkillRecommendation = await this.actionRecommendationService.getActionsForBaseRecommendationSkill(laliloBaseRecommendationSkill, students, classProductUsage);

      if (laliloSkillRecommendation) {
        laliloSkillRecommendation.rationale = this.getRationaleForStudentsWithSkill(laliloSkillRecommendation, students, Object.keys(laliloSkillRecommendation.productActionLinks).length > 0);
      }
    }

    return laliloSkillRecommendation;
  }

  public async getLaliloBaseRecommendationSkill(skills: Skill[]): Promise<BaseRecommendationSkill | null> {

    let aggregatedSkills = SkillAggregate.aggregate(skills);
    if (aggregatedSkills.length == 0) {
      return null;
    }
    // Determine which skill to base recommendation
    let stuckSkills = aggregatedSkills.filter(skill => SkillTests.isStuckSkill(skill));

    // Stuck skills -> Needs helping hand
    if (stuckSkills.length !== 0) {

      // filter for targeted. Lalilo does not have a concept of focus skills
      stuckSkills = this.filterSkillsByProductType(stuckSkills);

      // get most recent if there are multiple skills
      let mostRecentStuckSkill = SkillTests.getMostRecentSkill(stuckSkills);

      /* Recommend same skill
      All lalilo skills have a null renaissanceSkillId, which is why we're using contentActivityId */
      let baseRecommendationSkill: BaseRecommendationSkill = {
        skillId: mostRecentStuckSkill.contentActivityId,
        recommendationType: RecommendationType.NeedsHelp,
        subject: mostRecentStuckSkill.subject,
        practiceProductType: mostRecentStuckSkill.product
      };

      return baseRecommendationSkill;
    }

    // No stuck skills
    else {
      let relevantSkills = aggregatedSkills.filter(skill => SkillTests.hasThreeOrMoreItems(skill));

      // No skills with sufficient items
      if (relevantSkills.length == 0) {
        return null;
      }

      // Check if working at appropriate level
      let appropriateLevelSkills = relevantSkills.filter(skill => {
        let accuracyRate = SkillTests.getSkillAccuracyRate(skill);
        return SkillTests.isWorkingAtAppropriateLevelBasedOnAccuracyRate(accuracyRate) &&
          !SkillTests.isReadyForChallengeBasedOnAccuracyRate(accuracyRate);
      });

      // Working at an appropriate level
      if (appropriateLevelSkills.length > 0) {
        appropriateLevelSkills = this.filterSkillsByProductType(appropriateLevelSkills);

        // get most recent if there are multiple skills
        let mostRecentAppropriateSkill = SkillTests.getMostRecentSkill(appropriateLevelSkills);

        /* Recommend same skill
        All lalilo skills have a null renaissanceSkillId, which is why we're using contentActivityId */
        let baseRecommendationSkill: BaseRecommendationSkill = {
          skillId: mostRecentAppropriateSkill.contentActivityId,
          recommendationType: RecommendationType.Appropriate,
          subject: mostRecentAppropriateSkill.subject,
          practiceProductType: mostRecentAppropriateSkill.product
        };

        return baseRecommendationSkill;
      }

      // Ready for a Challenge
      let readyForMoreSkills = this.filterSkillsByProductType(relevantSkills);

      let mostRecentReadyForMoreSkill = SkillTests.getMostRecentSkill(readyForMoreSkills);

      /* Recommend same skill. Still need to recommend skill for ready for more to show nudge text
      Action recommendation service however will not have any action links */
      let baseRecommendationSkill: BaseRecommendationSkill = {
        skillId: mostRecentReadyForMoreSkill.contentActivityId,
        recommendationType: RecommendationType.NeedsChallenge,
        subject: mostRecentReadyForMoreSkill.subject,
        practiceProductType: mostRecentReadyForMoreSkill.product
      };

      return baseRecommendationSkill;
    }
  }

  public getRationale(baseRecommendationSkill: BaseRecommendationSkill, selectedStudent: StudentContext) {

    if (baseRecommendationSkill.skillId === this.FINAL_LALILO_LESSON_ID && baseRecommendationSkill.recommendationType === RecommendationType.NeedsChallenge) {
      return `${selectedStudent.firstName} is growing and ready to move up to using Freckle! We recommend them log in to start an adaptive practice. `
    }

    if (baseRecommendationSkill.practiceProductType && ProductTypeGroups.TargetedPracticeIds.includes(baseRecommendationSkill.practiceProductType)) {
      if (baseRecommendationSkill.recommendationType === RecommendationType.NeedsHelp) {
        return `${selectedStudent.firstName} is struggling on this lesson in Lalilo. In addition to reteaching, below are links to reassign the lesson in Lalilo and a resource for teacher-led instruction in whole or small groups.`;
      }
      if (baseRecommendationSkill.recommendationType === RecommendationType.Appropriate) {
        return `${selectedStudent.firstName} is working hard on this lesson in Lalilo. You may want to reassign it so they can keep practicing. In addition, below is a resource for teacher-led instruction in whole or small group sthat may help.`;
      }
      if (baseRecommendationSkill.recommendationType === RecommendationType.NeedsChallenge) {
        return `${selectedStudent.firstName} is doing great! Encourage them to continue their adaptive practice in Lalilo.`;
      }
    }
    else {
      if (baseRecommendationSkill.recommendationType === RecommendationType.NeedsHelp) {
        return `${selectedStudent.firstName} is struggling on this lesson in Lalilo adaptive practice. You may want to reteach this concept. Encourage them to keep going with Lalilo's adaptive pathway! Below is a resource for teacher-led instruction in whole or small groups that may help.`;
      }
      if (baseRecommendationSkill.recommendationType === RecommendationType.Appropriate) {
        return `${selectedStudent.firstName} is working hard on this lesson in Lalilo. Encourage them to keep going with Lalilo's adaptive pathway! Below is a resource for teacher-led instruction in whole or small groups that may help.`;
      }
      if (baseRecommendationSkill.recommendationType === RecommendationType.NeedsChallenge) {
        return `${selectedStudent.firstName} is doing great in their Lalilo adaptive practice! Encourage them to keep going!`;
      }
    }
    // default fallback
    return `Based on ${selectedStudent.firstName}'s latest work in Lalilo:`;
  }

  public getRationaleForStudentsWithSkill(recommendation: Recommendation, students: StudentContext[], hasRecommendedActions: boolean): string {
    let rationaleText = '';

    // Add rationale if student(s) practice is:
    // 1. in Needs Challenge category
    // 2. has no recommended actions
    if (recommendation.recommendationType === RecommendationType.NeedsChallenge &&
      !hasRecommendedActions &&
      recommendation.practiceProductType) {

      let practiceProductTypeText = '';

      // Targeted practice
      if (ProductTypeGroups.TargetedPracticeIds.includes(recommendation.practiceProductType)) {
        practiceProductTypeText = 'doing great! Encourage them to keep going!';
      }

      // Adaptive practice
      else {
        practiceProductTypeText = 'doing great on their Lalilo adaptive practice! Encourage them to keep going!';
      }

      const numStudents = students.length;
      if (numStudents == 1) {
        rationaleText = 'This student is ' + practiceProductTypeText;
      }
      else {
        rationaleText = 'These students are ' + practiceProductTypeText;
      }
    }

    return rationaleText;
  }

  public filterSkillsByProductType(skills: Skill[]): Skill[] {
    if (skills.find(skill => ProductTypeGroups.TargetedPracticeIds.includes(skill.product))) {
      skills = skills.filter(skill => ProductTypeGroups.TargetedPracticeIds.includes(skill.product));
    }

    return skills;
  }
}
