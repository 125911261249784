import { Injectable } from '@angular/core';
import { ClassesService } from '../classes/classes.service';
import { LicenseInfoService } from '../license-info/license-info.service';
import { ClassActivity, TJClass } from 'src/app/shared/models/class.model';
import { ProductAppCodes, ProductAppTags } from '../product-info/product-info.service';

@Injectable({
  providedIn: 'root'
})
export class LaliloLinkGeneratorV2Service {

  constructor(
    private classesService: ClassesService,
    private licenseInfoService: LicenseInfoService
  ) { }

  public async getLaliloPageDeepLink(pageName: string, lessonId: string | undefined = undefined) {

    let classId = await this.getLaliloClass();

    let laliloInterAppContext: any = {
      pageName: pageName,
      renaissanceClassId: classId
    };

    if (lessonId) {
      laliloInterAppContext.laliloLessonId = lessonId;
    }

    return encodeURIComponent(JSON.stringify(laliloInterAppContext));    
  }  

  private async getLaliloClass() {
    let classId = '';
    let selectedClass = this.classesService.selectedClass$.getValue();

    // Check if selected class has lalilo assigned
    if (this.classActivityHasLalilo(selectedClass!)) {
      return selectedClass!.renaissanceClassId;
    }

    // Check other classes if selected class does not have lalilo
    if (await this.licenseInfoService.currentClassesHaveLalilo()) {
      let laliloClasses = (await this.classesService.getClasses()).filter(c => this.tjClassHasLalilo(c));
      classId = laliloClasses[0].classRenaissanceId;
    }

    return classId;
  }

  private classActivityHasLalilo(classActivity: ClassActivity) {
    return classActivity.appTags.includes(ProductAppCodes.Lalilo);
  }

  private tjClassHasLalilo(tjClass: TJClass) {
    return tjClass.classAppTags?.includes(ProductAppCodes.Lalilo);
  }
}
