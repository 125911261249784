import { ViewportScroller } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PendoService } from './services/pendo/pendo.service';
import { SideBarExpanderService } from './services/side-bar-expander/side-bar-expander.service';
import { UserService } from './services/user/user.service';
import { IdleTimeoutService } from './idle/idle-timeout.service';
import { IdleTimeoutConstants } from './idle/idle-timeout.config';
import { HeaderElementConfig } from './shared/models/header-element-config';
import { HeaderConfigService } from './services/header-config/header-config.service';
import { Observable, Subscription } from 'rxjs';
import { HelpIdService } from './services/help-id/help-id.service';
import { ClassContext } from './shared/models/class.model';
import { ContextDataService } from './services/context-data/context-data.service';
import { NewRelicInstrumentationService } from './services/new-relic-instrumentation/new-relic-instrumentation.service';
import { AppNavItemService } from './services/app-nav/app-nav-item.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav: MatDrawer | null = null;
  public isLoggedIn: Promise<boolean>;
  public lastFocusedElement: HTMLElement | null = null;
  private returnFocusListener: ((focusEvent: FocusEvent) => any) | undefined;
  public headerElementConfig: HeaderElementConfig | null = null;
  public headerElementUrl: string = '';
  public helpUrl$: Observable<string> = new Observable<string>();

  private subscriptions: Subscription[] = [];

  constructor(
    private pendoService: PendoService,
    private newRelicService: NewRelicInstrumentationService,
    private viewportScroller: ViewportScroller,
    private sideBarExpanderService: SideBarExpanderService,
    public userService: UserService,
    private idle: IdleTimeoutService,
    private headerConfigService: HeaderConfigService,
    private helpIdService: HelpIdService,
    private contextDataService: ContextDataService,
    private appNavItemService: AppNavItemService,
    private router: Router
  ) {
    this.isLoggedIn = this.userService.isLoggedIn();
  }

  async ngOnInit() {
    this.pendoService.initialize();
    this.newRelicService.initializeNewRelicInstrumentation();
    this.sideBarExpanderService.expanded$.subscribe(expanded => {
      this.sidenav?.toggle(expanded);
    });

    // Idle timeout monitoring
    await this.isLoggedIn;
    IdleTimeoutService.configure({
      timeoutMinutes: this.userService.getTimeoutMinutes(),
      keepaliveIntervalMinutes: this.userService.getKeepAliveIntervalMinutes(),
      keepaliveUrl: this.userService.getZoneUri(),
      logoutUrl: this.userService.getLogoutUri(),
      timeToRespondMinutes: IdleTimeoutConstants.DefaultTimeToRespondMinutes
    });

    this.idle.watch();
    this.idle.onIdleEnd(() => {
      this.lastFocusedElement?.focus();
    });
    this.idle.onIdleTimeout(() => {
      localStorage.removeItem('token');
    });

    this.returnFocusListener = (focusEvent: FocusEvent) => {
      var modalContainer = document.querySelector("#rl-timeout-modal-container");
      if (!modalContainer?.contains(focusEvent.target as HTMLElement)) {
        var target = focusEvent.target;
        this.lastFocusedElement = target as HTMLElement;
      };
    };

    // Header config
    this.headerElementConfig = await this.headerConfigService.getConfig();
    this.headerElementUrl = this.headerConfigService.getElementUrl();

    let subscription = this.contextDataService.classDataContext$.subscribe(async (classData: ClassContext | undefined) => {
      if (classData) {
        this.headerElementConfig = await this.headerConfigService.getConfig();
      }
    });
    this.subscriptions.push(subscription);

    // Help URL
    this.helpIdService.initHelpIdService(this.headerElementConfig.culture);
    this.helpUrl$ = this.helpIdService.helpUrl$;

    document.addEventListener("focusout", this.returnFocusListener);

    // New user has not been synched -> Getting Things Ready
    let userDetail = (await this.userService.getUserDetail());
    let isDegradedNav = this.appNavItemService.getIsDegraded();

    if (!userDetail && !isDegradedNav) {
      let notReadyReason = 'no-user-detail-response';
      this.newRelicService.recordTeacherNotReadyEvent(notReadyReason);

      this.router.navigate(['/synching']);
    }
  }

  ngOnDestroy(): void {
    document.removeEventListener("focusout", this.returnFocusListener as any);
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public navigateSkipLink(elementId: string): void {
     // Get the element by ID
     this.viewportScroller.scrollToAnchor(elementId);
     const element = document.getElementById(elementId);
     if (element) {
       // Calculate the position to scroll to, with the margin
       const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
       const position = elementPosition - 200;

       // Scroll to the position
       this.viewportScroller.scrollToPosition([0, position]);
     }
  }
}
