<div class="renaissance-style-scoped">
    <div class="header-container">
        <span tabindex="0" class="card-picker-text" *ngIf="data">Card for <span class="card-picker-text-student-name">{{data.name}}</span></span>
    </div>
    <mat-divider aria-hidden="true" class="category-divider">
    </mat-divider>
    <div class="body-container" *ngIf="hasLoaded">
        <div class="cards-container-main" *ngFor="let grade of gradeCards">
            <div class="recommended-for-grad">Recommended for Grades {{grade.text}}</div>
            <div class="cards-container">
                <div class="card" *ngFor="let card of grade.cards">
                    <div tabindex="0" class="card-image-container" [ngClass]="{'selected-card': card.isSelected}"
                        (click)="onCardSelect(card)" (keyup.enter)='onCardSelect(card)'>
                        <div>
                            <img src="../../../../assets/superstar-card-images/{{card.icon}}" alt="Placeholder Image"
                                class="card-image">
                        </div>
                        <div>
                            <span *ngIf="card.isSelected" class="glyph-checkmark-circle2"></span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="body-container" *ngIf="!hasLoaded">
        <mat-spinner mode="indeterminate" class="mat-spinner"> </mat-spinner>
    </div>

    <mat-divider aria-hidden="true" class="category-divider">
    </mat-divider>
    <div class="footer">
        <div class="footer-button">
            <button class="ren-btn ren-btn--secondary select-card-btn" (click)="close($event)">Select Card</button>
        </div>
    </div>
</div>