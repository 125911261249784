<div>
  <mat-expansion-panel [id]="id"
      (opened)="panelStateText = 'Hide Details';panelStateGlyph ='glyph-chevron-collapsed';onExpansionOpen()"
      (closed)="panelStateText = 'View Details';panelStateGlyph ='glyph-chevron-expanded';onExpansionClose()"
      class="activity-summary-accordion"
      [ngClass]="{'show-hover-border' : hasStudents}"
      [disabled]="!hasStudents">
    <mat-expansion-panel-header aria-hidden="false">
      <ng-template [ngTemplateOutlet]="categoryTemplate"></ng-template>
    </mat-expansion-panel-header>
    <time-to-practice-recommendation
      *ngIf="id=='category-time-to-practice' && hasStudents"
      [attr.aria-hidden]="!isExpanded"
      [classProducts]="classProducts"
    >
    </time-to-practice-recommendation>
    <student-table *ngIf="hasStudents" [attr.aria-hidden]="!isExpanded" [students]="students" [category]="id" [classProducts]="classProducts" [showTakeAction]="showTakeAction"></student-table>
  </mat-expansion-panel>
</div>

<ng-template #categoryTemplate>
  <div class="summary-student-category-header">
    <div class="category-left-container">
      <div class="category-rounded-corner" [id]="id"></div>
    </div>
    <div class="category-content-container">
      <div class="category-info">
        <div class="category-name-container">
          <h3 class="category-name" tabindex="-1">
            {{headerTitle}} <span class="category-count-side">({{students.length}} of {{numTotalStudents}} student{{numTotalStudents == 1 ? '' : 's'}})</span>
          </h3>
        </div>
        <div class="category-description" tabindex="-1" >
          {{description}}
        </div>
      </div>
      <div class="no-students" *ngIf="!hasStudents">
        No Students to View
      </div>
      <div class="student-bubble-container category-expand" *ngIf="hasStudents">
        <div class="student-bubble-container">
          <app-student-bubble-stack tabindex="-1" [students]="students"></app-student-bubble-stack>
        </div>
      </div>
      <div class="student-anchor-container category-expand"  *ngIf="hasStudents"  >
        <a role="button" tabindex="-1" href="#" [attr.aria-label]="headerTitle" [attr.aria-expanded]="isExpanded" (click)="$event.preventDefault()">
          <div class="category-view-details-toggle" tabindex="-1" aria-disabled="true" > <a href="/" style="display:none">{{panelStateText}}</a> <span class="glyph" [ngClass]="panelStateGlyph"  aria-disabled="true"></span></div>
          <div class="screen-reader-info" aria-live="polite">
            {{ isExpanded ? headerTitle + 'expanded' : headerTitle + 'collapsed' }}
          </div>
        </a>
      </div>
    </div>
  </div>
</ng-template>
