import { Student } from "src/app/shared/models/student.model";

export interface SuperstarSelectCardData {
    id: string
    name: string;
    icon: string;
    isSelected: boolean;
}

export interface SuperstarSVGCard extends Student {
    svgIcon: string;
    gradeRange : string;
}

export interface SuperstarCardPrint extends Student {
      svgIcon: string;
    studentId: string;
  studentName: string;
  teacherName: string;
      message: string;
}

export const gradeCards: { grade: string, text: string, cards: SuperstarSelectCardData[] }[] = [{
    grade: "K-2", text: "K-2", cards: [
      { id: '1-01', name: 'superstarbadge-lvl_1-01', icon: "superstarbadge-lvl_1-01.svg", isSelected: false },
      { id: '1-02', name: 'superstarbadge-lvl_1-02', icon: "superstarbadge-lvl_1-02.svg", isSelected: false },
      { id: '1-03', name: 'superstarbadge-lvl_1-03', icon: "superstarbadge-lvl_1-03.svg", isSelected: false },
      { id: '1-04', name: 'superstarbadge-lvl_1-04', icon: "superstarbadge-lvl_1-04.svg", isSelected: false }
    ]
  },
  {
    grade: "3-5", text: "3-5", cards: [
      { id: '2-01', name: 'superstarbadge-lvl_2-01', icon: "superstarbadge-lvl_2-01.svg", isSelected: false },
      { id: '2-02', name: 'superstarbadge-lvl_2-02', icon: "superstarbadge-lvl_2-02.svg", isSelected: false },
      { id: '2-03', name: 'superstarbadge-lvl_2-03', icon: "superstarbadge-lvl_2-03.svg", isSelected: false },
      { id: '2-04', name: 'superstarbadge-lvl_2-04', icon: "superstarbadge-lvl_2-04.svg", isSelected: false }
    ]
  },
  {
    grade: "6-12", text: "6-12", cards: [
      { id: '3-01', name: 'superstarbadge-lvl_3-01', icon: "superstarbadge-lvl_3-01.svg", isSelected: false },
      { id: '3-02', name: 'superstarbadge-lvl_3-02', icon: "superstarbadge-lvl_3-02.svg", isSelected: false },
      { id: '3-03', name: 'superstarbadge-lvl_3-03', icon: "superstarbadge-lvl_3-03.svg", isSelected: false },
      { id: '3-04', name: 'superstarbadge-lvl_3-04', icon: "superstarbadge-lvl_3-04.svg", isSelected: false },
      { id: '3-05', name: 'superstarbadge-lvl_3-05', icon: "superstarbadge-lvl_3-05.svg", isSelected: false }
    ]
  }];
