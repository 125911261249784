import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClassesService } from 'src/app/services/classes/classes.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { SchoolsService } from 'src/app/services/schools/schools.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { SubjectTypes } from 'src/app/services/subject/subject-types';
import { SubjectService } from 'src/app/services/subject/subject.service';
import { TJClass, ClassActivity } from 'src/app/shared/models/class.model';
import { School } from 'src/app/shared/models/school.model';

export enum LocalStorageKeys{
  selectedSchoolId = 'selectedSchoolId',
  selectedClassId = 'selectedClassId'
};

@Component({
  selector: 'context-selector',
  templateUrl: './context-selector.component.html',
  styleUrls: ['./context-selector.component.scss']
})
export class ContextSelector implements OnInit, OnDestroy {
  public tjClasses: TJClass[] = [];
  public schools: School[] = [];
  public subjects: { id: string, displayValue: string }[] = [ContextSelector.subjectMathOption, ContextSelector.subjectReadingOption];
  public selectedSubject: { id: string, displayValue: string } | undefined = ContextSelector.subjectMathOption;
  public selectedClassActivity: ClassActivity | undefined;
  public selectedTJClass: TJClass | undefined;
  public selectedSchool: School | undefined;
  private subscriptions: Subscription[] = [];
  public static readonly subjectMathOption = { id: SubjectTypes.MATH, displayValue:'Math' };
  public static readonly subjectReadingOption = { id: SubjectTypes.READING, displayValue:'Reading' };

  @Input() isSuperstar = false;
  public constructor(
    private subjectService: SubjectService,
    private schoolsService: SchoolsService,
    private classesService: ClassesService,
    private spinnerService: SpinnerService,
    private localStorage: LocalStorageService) {
  }

  public async ngOnInit(): Promise<void> {
    const selectedSchoolSub = this.schoolsService.selectedSchool$.subscribe((selectedSchool) => {
      this.selectedSchool = selectedSchool;
    });

    const selectedClassSub = this.classesService.selectedClass$.subscribe((selectedClass) => {
      this.selectedClassActivity = selectedClass;
    });

    const selectedSubjectSub = this.subjectService.selectedSubject$.subscribe((selectedSubject) => {
      this.selectedSubject = this.subjects.find((subject) => subject.id === selectedSubject)
    });

    this.schools = await this.schoolsService.getSchools();
    this.initializeSelection();

    this.subscriptions.push(selectedSchoolSub);
    this.subscriptions.push(selectedClassSub);
    this.subscriptions.push(selectedSubjectSub);

    this.updateSelectedSubject(this.selectedSubject);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public updateSelectedSubject(subject: {id: string, displayValue: string} | undefined) {
    if (!!subject) {
      this.subjectService.selectedSubject$.next(subject.id);
    }
  }

  public async initializeSelection() {
    if (!this.selectedSchool) {
      const storedSchoolId = this.localStorage.getItem(LocalStorageKeys.selectedSchoolId);
      const storedSchool = this.schools.find(school => school.schoolRenaissanceId == storedSchoolId)
      if (storedSchool) {
        this.updateCurrentSchool(storedSchool);
      }
      else {
        this.updateCurrentSchool(this.schools[0]);
      }
    }
    else {
      this.updateCurrentSchool(this.selectedSchool);
    }
  }

  public async updateCurrentSchool(currentSchool: School | undefined) {
    if (currentSchool) {
      this.spinnerService.spinnerVisible$.next(true);

      let schoolRenaissanceId = currentSchool.schoolRenaissanceId;
      this.localStorage.setItem(LocalStorageKeys.selectedSchoolId, schoolRenaissanceId);
      this.tjClasses = await this.classesService.refreshClasses(schoolRenaissanceId);

      this.schoolsService.selectedSchool$.next(currentSchool);

      let defaultClass = this.getDefaultClass();
      this.updateCurrentClass(defaultClass);
    }
  }

  public async updateCurrentClass(currentClass: TJClass | undefined) {
    if (currentClass) {
      this.spinnerService.spinnerVisible$.next(true);

      let className = currentClass.className;
      let classRenaissanceId = currentClass.classRenaissanceId;
      let classAppTags = currentClass.classAppTags;
      let teacherRenaissanceId = currentClass.userRenaissanceId;
      this.selectedTJClass = currentClass;
      this.localStorage.setItem(LocalStorageKeys.selectedClassId, currentClass.classRenaissanceId);

      this.selectedClassActivity = await this.classesService.updateClassActivity(className, classRenaissanceId, classAppTags, teacherRenaissanceId);
    }
  }

  private getDefaultClass(): TJClass {
    const storedClassId = this.localStorage.getItem(LocalStorageKeys.selectedClassId);
    const storedClass = this.tjClasses.find(cls => cls.classRenaissanceId == storedClassId );
    if (storedClass) {
      return storedClass;
    }
    return this.tjClasses[0];
  }
}
