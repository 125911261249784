import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription, combineLatest } from "rxjs";
import { ClassContext, ClassActivity } from "../../shared/models/class.model";
import { ClassProductUsage, StudentActivity, StudentContext } from "src/app/shared/models/student.model";
import { DateHelper } from "src/app/util/date-helper/date-helper";
import { SubjectService } from "../subject/subject.service";
import { Assessment } from "src/app/shared/models/assessment.model";
import { ClassesService } from "../classes/classes.service";
import { Skill } from "src/app/shared/models/skill.model";
import { SubjectTypes } from "../subject/subject-types";
import { SkillPracticeSummary } from "src/app/shared/models/skill-practice-summary.model";
import { ProductAppTags, ProductAppTagsByType, ProductIds, ProductInfoService } from "../product-info/product-info.service";
import { SpinnerService } from "../spinner/spinner.service";

@Injectable({
  providedIn: 'root'
})
export class ContextDataService implements OnDestroy {
  public classDataContext$ = new BehaviorSubject<ClassContext | undefined>(undefined);
  public classDataContextNoSubject$ = new BehaviorSubject<ClassContext | undefined>(undefined);

  private subscriptions: Subscription[] = [];

  constructor(
    subjectService: SubjectService,
    classesService: ClassesService,
    private productInfoService: ProductInfoService,
    private spinnerService: SpinnerService
  ) {
    let subscription = combineLatest([subjectService.selectedSubject$, classesService.selectedClass$])
      .subscribe(([selectedSubject, selectedClass]) => {
        if (selectedClass != undefined) {
          let classContextResult = this.getClassContext(selectedSubject, selectedClass);
          let classContextNoSubjectResult = this.getClassContext(SubjectTypes.NONE, selectedClass);

          this.classDataContext$.next(classContextResult);
          this.classDataContextNoSubject$.next(classContextNoSubjectResult);

          this.spinnerService.spinnerVisible$.next(false);
        }
      });
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public getClassContext(subject: string, classActivity: ClassActivity): ClassContext {
    let classProductUsage: ClassProductUsage = {
      hasPracticeProductAssigned: false,
      hasPracticeActivity: false,
      hasAssessmentActivity: false
    };

    let classContext: ClassContext = {
      title: classActivity.title,
      renaissanceClassId: classActivity.renaissanceClassId,
      appTags: classActivity.appTags,
      students: classActivity.students.map((student: StudentActivity) => this.getStudentContext(subject, classProductUsage, student)),
      classProducts: {
        skillsPracticeProducts: classActivity.appTags
          .filter(appTag => ProductAppTagsByType.SkillPracticeProductAppTags.includes(appTag)) // only get the app tags included in the practice product
          .map(appTag => this.productInfoService.GetProductAppDisplayName(appTag)) // map to get the actual names
          .filter((value, index, self) => self.indexOf(value) === index), // make sure the array has distinct values
        readingPracticeProducts: classActivity.appTags
          .filter(appTag => ProductAppTagsByType.ReadingPracticeProductAppTags.includes(appTag))
          .map(appTag => this.productInfoService.GetProductAppDisplayName(appTag))
          .filter((value, index, self) => self.indexOf(value) === index),
        assessmentProducts: classActivity.appTags
          .filter(appTag => ProductAppTagsByType.AssessmentProductAppTags.includes(appTag))
          .map(appTag => this.productInfoService.GetProductAppDisplayName(appTag))
          .filter((value, index, self) => self.indexOf(value) === index)
      }
    }

    // filter lalilo for class's skillsPracticeProducts if subject is not reading
    if (subject != SubjectTypes.READING) {
      classContext.classProducts.skillsPracticeProducts = classContext.classProducts.skillsPracticeProducts.
        filter(product => product != this.productInfoService.GetProductAppDisplayName(ProductAppTags.Lalilo));
    }

    // Check if class has practice activity -> students without data are displayed differently depending on if the class is active
    classProductUsage.hasPracticeProductAssigned = this.getClassHasPracticeProductAssigned(classContext.appTags);
    classProductUsage.hasPracticeActivity = classProductUsage.hasPracticeProductAssigned && this.getClassHasPracticeActivity(classContext);

    // Check if class has assessment activity -> students without data are displayed differently depending on if the class is active
    classProductUsage.hasAssessmentActivity = this.getClassHasAssessmentActivity(classContext);

    return classContext;
  }

  private getStudentContext(subject: string, classProductUsage: ClassProductUsage, student: StudentActivity): StudentContext {
    // Filter skills by subject
    let filteredSkills = subject === SubjectTypes.NONE ? student.skills :
      student.skills.filter(skill => skill.subject === subject)

    // Filter skills and practiced date (within 2 weeks) and sort
    filteredSkills = filteredSkills
      .filter(skill => DateHelper.isWithinTwoWeeksOfToday(skill.lastPracticedDate))
      .sort((a, b) => this.compareBySkillPracticeDate(a, b)) ?? null;
    let minutesSpentPerDay = this.getMinutesSpentPerDay(filteredSkills);

    const filteredSummary = subject === SubjectTypes.NONE ? student.skillPracticeSummary.sort((a, b) => this.compareBySkillPracticeSummaryDate(a, b))[0] :
      student.skillPracticeSummary.find(summary => summary.subject === subject)

    // Filter assessments by subject and sort
    let filteredAssessments = subject === SubjectTypes.NONE ? student.assessments :
      student.assessments.filter(assessment => assessment.subject === subject);

    filteredAssessments = filteredAssessments
      .filter(assessment => assessment.productId != ProductIds.AR)
      .sort((a, b) => this.compareByAssessmentDate(a, b)) ?? null;

    const latestAssessment = filteredAssessments[0] ?? null;

    let studentContext: StudentContext = {
      firstName: student.firstName,
      lastName: student.lastName,
      grade: student.grade,
      renaissanceId: student.renaissanceId,
      schoolRenaissanceId: student.schoolRenaissanceId,
      skills: filteredSkills,
      latestAssessment: latestAssessment,
      assessments: filteredAssessments,
      lastSkillPracticedDate: filteredSummary?.lastSkillPracticedDate,
      minutesSpentPerDay: minutesSpentPerDay,
      classProductUsage: classProductUsage,
      studentIds: student.studentIds,
      studentClassIds: student.studentClassIds,
      readingPractices: student.readingPractices,
      readingPracticeSummary: student.readingPracticeSummary
    }
    return studentContext;
  }

  private compareBySkillPracticeDate(a: Skill, b: Skill): number {
    return DateHelper.compareByDate(a.lastPracticedDate, b.lastPracticedDate);
  }

  private compareBySkillPracticeSummaryDate(a: SkillPracticeSummary, b: SkillPracticeSummary): number {
    return DateHelper.compareByDate(a.lastSkillPracticedDate!, b.lastSkillPracticedDate!);
  }

  private compareByAssessmentDate(a: Assessment, b: Assessment) {
    return DateHelper.compareByDate(a.completedDate, b.completedDate);
  }

  private getMinutesSpentPerDay(skills: Skill[]): number {
    let durationsInMinutes = skills.map(skill => skill.durationInMinutes);
    return Math.ceil(durationsInMinutes.reduce((a, b) => a + b, 0) / 14); // Average practice duration over the last 2 weeks (14 days)
  }

  private getClassHasPracticeProductAssigned(classAppTags: string[]): boolean {
    return classAppTags.some(appTag => ProductAppTagsByType.SkillPracticeProductAppTags.includes(appTag));
  }

  private getClassHasPracticeActivity(classContext: ClassContext): boolean {
    let classHasPracticeActivity: boolean = false;
    let students = classContext.students;

    for (let i = 0; i < students.length; i++) {
      if (DateHelper.isWithinTwoWeeksOfToday(students[i].lastSkillPracticedDate)) {
        classHasPracticeActivity = true;
        break;
      }
    }
    return classHasPracticeActivity;
  }

  private getClassHasAssessmentActivity(classContext: ClassContext): boolean {
    let students = classContext.students;
    return !!students.find(student => student.latestAssessment != null);
  }
}
