import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SuperstarSelectCardModalComponent } from '../superstar-select-card-modal/superstar-select-card-modal.component';
import { SuperstarSVGCard, SuperstarSelectCardData, gradeCards } from '../superstar-select-card-modal/superstar-select-card-data';
import { ClassContext } from 'src/app/shared/models/class.model';
import { ContextDataService } from 'src/app/services/context-data/context-data.service';
import { Subscription } from 'rxjs';
import { SuperstarService } from 'src/app/services/superstar/superstar.service';
import { ElementRef, ViewChild } from '@angular/core';
import { Superstar } from 'src/app/shared/models/superstar.model';
import { Student } from 'src/app/shared/models/student.model';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/services/user/user.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'app-superstar-data',
  templateUrl: './superstar-data.component.html',
  styleUrls: ['./superstar-data.component.scss']

})
export class SuperstarDataComponent implements OnInit, OnDestroy {
  public readonly recipient_lvl_1_to_img: string = '../../../../assets/recipient-lvl_1-01.svg';
  public readonly recipient_lvl_3_to_img: string = '../../../../assets/recipient-lvl_3-01.svg';
  @ViewChild('printableArea') printableArea!: ElementRef;
  public superstars: Superstar[] = [];
  public superstarsCards: SuperstarSVGCard[] = [];
  matDialogRef: any;
  private subscriptions: Subscription[] = [];
  textValue: string = '';
  formattedDate: string = '';
  studentIds: string = '';
  message: string = '';
  teacherName: string = '';



  public isLoaded: boolean = false;
  public showSpinner: boolean = false;
  gradeCards: { grade: string, text: string, cards: SuperstarSelectCardData[] }[] = gradeCards

  constructor(
    public breakpointObserver: BreakpointObserver,
    private contextDataService: ContextDataService,
    private superstarDataservice: SuperstarService,
    public matDialog: MatDialog,

    public userService: UserService,
    private datePipe: DatePipe,
    private spinnerService: SpinnerService
  ) { }

  public async ngOnInit() {
    let subscription = this.contextDataService.classDataContextNoSubject$.subscribe((classData: ClassContext | undefined) => {
      if (classData) {
        this.updateSuperstars(classData);
        this.getTeacherName();
      }
    });
    this.subscriptions.push(subscription);

    subscription = this.spinnerService.spinnerVisible$.subscribe((spinnerVisible) => {
      this.showSpinner = spinnerVisible;
    });

    const currentDate = new Date();
    this.formattedDate =
      this.datePipe.transform(currentDate, 'yyyy, MMMM d') ?? '';
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private async updateSuperstars(selectedClass: ClassContext) {
    this.superstars = [];
    this.isLoaded = false;
    this.superstars = await this.superstarDataservice.getSuperstars(selectedClass.students);
    this.bindSuperstarsCards();
    this.isLoaded = true;
  }

  public openSuperstarSelectCardModal(student: any) {
    let SuperstarSelectCardData: SuperstarSelectCardData = {
      id: student.renaissanceId,
      name: student.firstName + ' ' + student.lastName,
      icon: this.getSuperstarCard(student),
      isSelected: false,
    };

    this.matDialogRef = this.matDialog.open<
      SuperstarSelectCardModalComponent,
      SuperstarSelectCardData
    >(SuperstarSelectCardModalComponent, {
      restoreFocus: true,
      data: SuperstarSelectCardData,
    });

    if (this.matDialogRef) {
      this.matDialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          let index = this.superstarsCards.findIndex(
            (card) => card.renaissanceId === result.renaissanceId
          );
          if (index > -1) {
            this.superstarsCards[index] = result;
          } else {
            this.superstarsCards.push(result);
          }
        }
      });
    }
  }

  public getSuperstarCard(student: Student) {
    let selectCard = this.superstarsCards.find(
      (card) => card.renaissanceId === student.renaissanceId
    );
    return selectCard ? selectCard.svgIcon : this.getSuperstarDefaultImage(student.grade);
  }

  getSuperstarDefaultImage(grade: any) {
    switch (grade) {
      case 'KG':
      case '01':
      case '1':
      case '02':
      case '2':
      return this.getSuperstarDefaultImageBasedOnGrade('K-2');
      case '03':
      case '3':
      case '04':
      case '4':
      case '05':
      case '5':
        return this.getSuperstarDefaultImageBasedOnGrade('3-5');
      case '06':
      case '6':
      case '07':
      case '7':
      case '08':
      case '8':
      case '09':
      case '9':
      case '10':
      case '11':
      case '12':
        return this.getSuperstarDefaultImageBasedOnGrade('6-12');
      default:
        return this.getSuperstarDefaultImageBasedOnGrade('');
    }
  }

  getSuperstarDefaultImageBasedOnGrade(grade: string) {
    let card = this.gradeCards.find(gradeCard => gradeCard.grade === grade);
    if (card) {
      if(this.superstarsCards && this.superstarsCards.length > 0){
        let randomIndex = this.superstarsCards.length % card.cards.length;
        return  card.cards[randomIndex].icon;
      }
      else{
        return  card.cards[0].icon;
      }
    }
    else{
      return this.gradeCards[0].cards[0].icon;
    }
  }

  bindSuperstarsCards() {
    this.superstarsCards = [];
    for (let index = 0; index < this.superstars.length; index++) {
      const student = this.superstars[index];
      let studentObj: any = {
        renaissanceId: student.student.renaissanceId,
        svgIcon: this.getSuperstarDefaultImage(student.student.grade),
        grade: student.student.grade
      }
      this.superstarsCards.push(studentObj);
    }
  }

  public getSuperstarCardToImg(student: Student) {
    let selectCard = this.superstarsCards.find(
      (card) => card.renaissanceId === student.renaissanceId
    );
       let toImage;
    if (selectCard?.grade == '6-12') {
      toImage = this.recipient_lvl_3_to_img;
    } else {
      toImage = this.recipient_lvl_1_to_img;
    }
    return toImage;
  }


  public getStudentMessage(student: Student) {
    let studentMessage = (<HTMLInputElement>(
      document.getElementById('txtArea_' + student.renaissanceId)
    )).value;
    return studentMessage;
  }

  private async getTeacherName() {
    const user = await this.userService.getUserDetail();
    this.teacherName = user.firstName + ' ' + user.lastName;
  }

}
