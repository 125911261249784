import { Component, Inject, Input, OnInit } from '@angular/core';
import { SearchResult } from '../search-result.model';
import { DOCUMENT } from '@angular/common';
import { FreckleLinkGeneratorService } from 'src/app/services/link-generators/freckle-link-generator.service';
import { ClassesService } from 'src/app/services/classes/classes.service';
import { ClassContext, TJClass } from 'src/app/shared/models/class.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LaliloLinkGeneratorService } from 'src/app/services/link-generators/lalilo-link-generator.service';
import { ContextDataService } from 'src/app/services/context-data/context-data.service';
import { AdminHandlerService } from 'src/app/services/admin-handler/admin-handler.service';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';
import { FreckleLinkGeneratorV2Service } from 'src/app/services/link-generators-v2/freckle-link-generator-v2.service';
import { LaunchControlService } from 'src/app/services/launch-control/launch-control.service';
import { ProductAppCodes } from 'src/app/services/product-info/product-info.service';
import { LaliloLinkGeneratorV2Service } from 'src/app/services/link-generators-v2/lalilo-link-generator-v2.service';

@Component({
  selector: '[search-result]',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {
  private window: Window;
  private nearpodId: string = 'SOURCE_NEARPOD';
  private freckleId: string = 'SOURCE_FRECKLE';
  private laliloId: string = 'SOURCE_LALILO';
  public NEARPOD_IMAGE_PATH = `../../../../../assets/logos/nearpod_logo.svg`
  public FRECKLE_IMAGE_PATH = `../../../../../assets/logos/freckle_logo.svg`
  public LALILO_IMAGE_PATH = `../../../../../assets/logos/lalilo_logo.svg`
  public filteredTags: { key: string, value: string[] }[] = [];
  public btnViewText: string = 'Lesson';
  private gradeSortMap = [
    { grade: 'K', sort: 0 },
    { grade: '1', sort: 1 },
    { grade: '2', sort: 2 },
    { grade: '3', sort: 3 },
    { grade: '4', sort: 4 },
    { grade: '5', sort: 5 },
    { grade: '6', sort: 6 },
    { grade: '7', sort: 7 },
    { grade: '8', sort: 8 },
    { grade: '9', sort: 9 },
    { grade: '10', sort: 10 },
    { grade: '11', sort: 11 },
    { grade: '12', sort: 12 },
    { grade: 'Other', sort: 13 },
    { grade: 'Higher Ed', sort: 14 }
  ]

  @Input() public searchResult!: SearchResult;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public adminHanlderService: AdminHandlerService,
    private freckleLinkGeneratorService: FreckleLinkGeneratorService,
    private contextDataService: ContextDataService,
    private classesService: ClassesService,
    private snackBar: MatSnackBar,
    private laliloLinkGeneratorService: LaliloLinkGeneratorService,
    public featureToggleService: FeatureToggleService,
    public freckleLinkGeneratorV2Service: FreckleLinkGeneratorV2Service,
    private launchControlService: LaunchControlService,
    private laliloLinkGeneratorV2Service: LaliloLinkGeneratorV2Service
  ) {
    this.window = this.document.defaultView as Window;
  }

  public ngOnInit(): void {
    this.filteredTags = this.searchResult.tags.filter(x => x.value.length > 0);
    //lesson_types
    let lesson_types = this.filteredTags.find(t => t.key == 'lesson_types');
    if (lesson_types && lesson_types.value.length > 0) {
      let btnViewText = `${lesson_types.value}`;
      this.setValidBtnViewText(btnViewText)
    }
  }

  setValidBtnViewText(lesson_types: any) {
    let trimText = lesson_types.trim();
    if (trimText) {
      this.btnViewText = trimText;
    }
  }

  public formatSource(source: string): string {
    if (this.isNearpod(source)) {
      return 'Nearpod';
    }
    else if (this.isFreckle(source)) {
      return 'Freckle';
    }
    else if (this.isLalilo(source)) {
      return 'Lalilo';
    }
    return '';
  }

  public formatStandard(result: SearchResult): string {
    return result.standard_set_name + '.' + result.standard_name
  }

  public isNearpod(source: string) {
    return source == this.nearpodId;
  }

  public isFreckle(source: string) {
    return source == this.freckleId;
  }

  public isLalilo(source: string) {
    return source == this.laliloId;
  }

  public formatTag(key: string, value: string[]) {
    if (key === 'grades') {
      return this.formatGrades(value);
    }
    if (key === 'products') {
      return this.formatProduct(value[0]);
    }
    if (key === 'lesson_types') {
      return `${value}`;
    }

    return `${value}`;
  }

  public async goToFreckle(standardId: string) {
    let students = this.contextDataService.classDataContext$.value?.students ?? [];
    if (await this.featureToggleService.isTrueAsync("use-new-launch")) {
      const deeplink = this.freckleLinkGeneratorV2Service.getSearchResultAssignSkillDeepLink(students, standardId, this.searchResult.renaissance_skill_id, this.searchResult.content_type);
      this.launchControlService.launchApp(ProductAppCodes.Freckle, deeplink, true);
    }
    else {
      const url = this.freckleLinkGeneratorService.getSearchResultAssignSkillLink(students, standardId, this.searchResult.renaissance_skill_id, this.searchResult.content_type);
      this.window.open(url, '_blank')?.focus();
    }
  }

  public async goToLalilo(productSkillId: string) {
    let matches = productSkillId.match(/LESSONID-(.*)__/);
    let lessonId = matches ? matches[1] : undefined;
    if (await this.featureToggleService.isTrueAsync("use-new-launch")) {
      const deeplink =  await this.laliloLinkGeneratorV2Service.getLaliloPageDeepLink('assignment-planning', lessonId);
      this.launchControlService.launchApp(ProductAppCodes.Lalilo, deeplink, true);
    }
    else {
      const url = await this.laliloLinkGeneratorService.getLaliloPageLink('assignment-planning', lessonId);
      this.window.open(url, '_blank')?.focus();
    }
  }

  private formatProduct(value: string) {
    if (['freckle', 'lalilo'].includes(value)) {
      return 'Student Practice';
    }
    else {
      return 'Teacher-led';
    }
  }

  private formatGrades(values: string[]) {
    values = values.map(x => {
      if (x === '0') return 'K';
      return x;
    });

    if (values.length === 1) {
      return `Grade ${values}`;
    }

    let valuesWithSortOrder = values.map(x => this.gradeSortMap.filter(y => y.grade === x)[0]);

    if (values.length > 1) {
      let minGradeSort = Math.min(...valuesWithSortOrder.map(x => x.sort));
      let maxGradeSort = Math.max(...valuesWithSortOrder.map(x => x.sort));

      let minGrade = this.gradeSortMap.filter(x => x.sort === minGradeSort)[0].grade;
      let maxGrade = this.gradeSortMap.filter(x => x.sort === maxGradeSort)[0].grade;

      return `Grades ${minGrade}-${maxGrade}`;
    }

    return '';
  }

  private classHasFreckle(classContext: ClassContext) {
    if (classContext?.students && classContext.students.length > 0) {
      let freckleStudents = classContext.students.filter(student => student.studentClassIds?.freckleClassId)
      if (freckleStudents.length > 0) {
        return true;
      }
    }
    return false;
  }
}
