import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { EducatorPortalInterappRedirectService } from 'src/app/services/educator-portal-interapp-redirect-service/educator-portal-interapp-redirect-service';


export interface DeepLinkContext {
  studentRenaissanceId: string;
  classRenaissanceId: string;
  schoolRenaissanceId: string;
  subject: string;
}


@Component({
  selector: 'product-deep-link',
  templateUrl: './product-deep-link.component.html',
  styleUrls: ['./product-deep-link.component.scss']
})
export class ProductDeepLinkComponent {

  @Input() zoneHomeUri: string = '';
  @Input() type: 'myon' | 'ar' | 'star' = 'ar';
  @Input() context?: DeepLinkContext;
  private window: Window;
  private readonly logoAssetBase = "../../../../../../../assets/logos/" as const;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private edPortalInterappRedirectService: EducatorPortalInterappRedirectService
  ) {
    this.window = this.document.defaultView as Window;
  }

  get imageLink() {
    switch(this.type) {
      case 'ar': return this.logoAssetBase + 'ar_logo.svg';
      case 'myon': return this.logoAssetBase + 'myon_logo.svg';
      case 'star': return this.logoAssetBase + 'star_logo.svg';
      default: throw Error('Invalid deep link image type');
    }
  }

  get labelText() {
    switch(this.type) {
      case 'ar': return 'AR Record Book';
      case 'myon': return 'myON Dashboard';
      case 'star': return 'Star Record Book';
      default: throw Error('Invalid label text type');
    }
  }

  private getStarBaseUriFromZoneUri() {
    if (this.zoneHomeUri.search("https://global-") == -1) {
      throw new Error('invalid Uri');
    }
    else {
      const zone = this.zoneHomeUri.slice(this.zoneHomeUri.indexOf("global-") + 7, this.zoneHomeUri.indexOf("."));
      const restOfDomain = this.zoneHomeUri.slice(this.zoneHomeUri.indexOf("renaissance"), this.zoneHomeUri.indexOf("com") + 3);
      return `https://${zone}-educator.${restOfDomain}/record-book/student/overall/`;
    }
  }

  private getRGPBaseUriFromZoneUri() {
    if (this.zoneHomeUri.search("https://global-") === -1 || this.zoneHomeUri.search("educatorportal") === -1) {
      throw new Error('invalid Uri');
    }
    else {
      const zoneBase = this.zoneHomeUri.split('educatorportal')[0];
      return zoneBase;
    }
  }

  public launchLink() {
    switch(this.type) {
      case 'ar': return this.linkToArRecordBook();
      case 'myon': return this.linkToMyOn();
      case 'star': return this.linkToStarRecordBook();
      default: throw Error('Invalid launch link type');
    }
  }


  public linkToStarRecordBook() {
    const student = this.context?.studentRenaissanceId;
    const school = this.context?.schoolRenaissanceId;
    const clazz = this.context?.classRenaissanceId;

    const subject = this.context?.subject.toLowerCase();
    const baseUri = this.getStarBaseUriFromZoneUri();

    const deepLinkUrl = `${baseUri}${subject}/gs_${school}/gs_${clazz}/gs_${student}/record-book`;
    this.window.open(deepLinkUrl, '_blank');
  }

  public linkToArRecordBook() {
    const student = this.context?.studentRenaissanceId;
    const school = this.context?.schoolRenaissanceId;
    const clazz = this.context?.classRenaissanceId;

    const arBaseUri = this.getRGPBaseUriFromZoneUri() + 'recordbook/ar/students/goals?'

    const deepLinkeUrl = `${arBaseUri}school_guid=gs_${school}&class_guid=gs_${clazz}&student_guid=gs_${student}`;
    this.window.open(deepLinkeUrl, '_blank');
  }

  public linkToMyOn() {
    // This is using the inter-app launcher mechanism in ed portal the "user flinger"
    // Read more here: https://illuminate.atlassian.net/wiki/spaces/RGP/pages/17272471588/Inter-App+Launcher+aka+User+Flinger#Inter-app-SSO-with-context-data
    // These ids are found in the web-EdcuatorPortal TileTaskConfiguration-en-us.json file
    const student = this.context?.studentRenaissanceId;
    const myonTaskId = 4900;
    const myonApplicationId = 49;
    // Used for myon 'redirect' claim 'admin-students-view?user_id=${student}'
    // Reason we aren't doing a direct pass through is to avoid malicious manipulation
    const context = `admin-students-view-user-id=${student}`;
    const deepLinkUrl = this.edPortalInterappRedirectService.getLaunchAndRedirectUrl(myonTaskId.toString(), myonApplicationId.toString(), context)
    this.window.open(deepLinkUrl, '_blank');
  }
  
 public onKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.launchLink();
    }
  }
}
