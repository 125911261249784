<div class="header-container" #headerContainer>
  <div *ngIf="hasLoaded && skillPracticeCategoryIsReadyForChallengeAndProductNotLaliloAndNotOverlapSkill()" class="rationale-header">
    {{getReadyForChallengeHeaderText()}}
  </div>
  <div *ngIf="hasLoaded && skillIsOverlap()" class="rationale-header">
    {{getOverlapSkillHeaderText()}}
  </div>
  <app-skill-description *ngIf="hasLoaded" [skillMetadata]="recommendation.skillMetadata!"
    [hasAmbiguousShortName]="data.hasAmbiguousShortName!" [isModalHeading]="true"></app-skill-description>
</div>

<mat-divider aria-hidden="true"
  class="category-divider">
</mat-divider>

<div class="body-container" *ngIf="hasLoaded">
  <div class="category-header-container">
      <img class="category-icon" alt="" [src]=data.categoryIcon>
      <p class="category-name">
        {{data.categoryName}}
        <span class="category-student-count">{{getStudentCountText()}}</span>
      </p>
  </div>
  <div class="student-bubble-container">
    <app-student-bubble-stack
      [students]="data.students"
    ></app-student-bubble-stack>
  </div>
  <div *ngIf="recommendation.rationale" class="rationale-message"
    [ngStyle]="{'width': headerContainer.offsetWidth + 'px'}">
    {{recommendation.rationale}}
  </div>
  <take-action-links-v2
    [recommendation]="recommendation">
  </take-action-links-v2>
</div>

<div class="body-container" *ngIf="!hasLoaded">
  <mat-spinner mode="indeterminate" diameter="40" class="mat-spinner"> </mat-spinner>
</div>

<mat-divider aria-hidden="true"
  class="category-divider">
</mat-divider>
<div class="footer">
  <div class="footer-button">
    <button class="ren-link" (click)="close($event)">Close</button>
  </div>
</div>
